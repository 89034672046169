/* COMMON
 * -------------------------------------------------- */

/* Scaling */

// html
// 	@media (min-width: 1600px)
// 		font-size: $baseSize + .75px

// 	@media (min-width: 2200px)
// 		font-size: $baseSize + 1.75px

html {
	margin-left: calc(100vw - 100%); }

body {}

.wrapper {
	&.page-admin-org,
	&.page-admin-wz {
		min-width: 102.4rem; }

	&.page-login {
		display: flex;
		flex-direction: column;
		min-height: 100vh;
		background: $color-5 url('/assets/img/login-bg.svg') no-repeat right -16.55rem bottom -9rem / 51.0596rem 58.3028rem;

		.main {
			padding-top: 7.5rem;
			padding-bottom: 7.5rem;
			flex: 1; } }

	@media (max-width: 767px) {
		&.page-user {
			background-color: $color-5; } }

	@media (max-width: 599px) {
		&.page-login {
			background-position: right -25.5rem bottom 0;

			.main {
				padding-top: 3rem;
				padding-bottom: 5rem;

				.container {
					padding: 0; }

				.success-message {
					max-width: 30rem; } } } } }

.content {
	min-height: 100vh;

	.article {
		flex: 1;
		padding: 12.6rem 0 3rem 2rem;
		margin-left: 26rem; } }

.page-user {
	@media (max-width: 1023px) {
		.content {
			.article {
				padding-top: 9.6rem;
				margin-left: 0;
				padding-right: 1rem;
				padding-left: 1rem; } } } }

/* GRID
 * -------------------------------------------------- */

.container {
	width: 100%;
	max-width: $maxViewPortWidth + 2;
	margin: 0 auto;
	padding-left: 1rem;
	padding-right: 1rem; }

.list {
	list-style: none;
	padding: 0;
	margin: 0;

	> li {
		list-style: none; }

	&-link {
		display: block; } }

/* TEXT MODIFICATION
 * -------------------------------------------------- */

.highlight {
	color: $colorMain; }

.text-center {
	text-align: center; }

/* HR
 * -------------------------------------------------- */

.dashed-separator {
	border-bottom: .1rem dashed $color-4;
	height: .1rem;
	margin: 3rem 0; }
