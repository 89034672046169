@import '../config/base';

.org-departmens {
  &-list {
    @include flex();
    margin: -4rem 0 0 -4rem; }

  .multiselect {
    @include flex-width(50%);
    padding-left: 4rem;
    margin-top: 4rem; }

  // &-item
  //   +flex-width(50%)
  //   padding-left: 4rem
  //   margin-top: 4rem

  // &-title
  //   +flex()
  //   align-items: center
  //   padding-bottom: .5rem

  //   span
  //     flex: 1
  //     color: $color-4
  //     padding-right: 3rem

  // .list
  //   border: .1rem solid $color-4
  //   height: 20.2rem
  //   overflow-y: auto
  //   padding: .8rem 0

  //   &-item
  //     line-height: 2rem
  //     padding: .7rem 1.7rem
  //     cursor: pointer
  //     transition: background-color .25s

  //     &.list-item--highlight,
  //     &:hover
  //       background-color: $color-3

  //     + .list-item
  //       margin-top: .5rem

  + .org-departmens {
    margin-top: 4rem; } }
