.list--links {
	display: flex;

	> * {
		min-width: 0; }

	.list-item {
		+ .list-item {
			margin-left: .9rem;
			padding-left: 1rem;
			position: relative;

			&::before {
				@include content();
				width: .1rem;
				height: 1.6rem;
				left: 0;
				top: 50%;
				margin-top: -.8rem;
				background: $baseColor; } } }

	.list-link,
	button {
		color: $colorMain;
		border: none;
		padding: 0;
		background: none;

		svg {
			display: inline-block;
			margin-right: 1rem;
			fill: $colorMain;
			transition: fill .25s; }

		&:hover {
			color: $color-7;

			svg {
				fill: $color-7; } } }

	&.list--links-icons {
		.list-item {
			+ .list-item {
				margin-left: 1.3rem;
				padding-left: 1.4rem; } } } }
