@import '../config/base';

.user-license {
	//padding: 0 5rem

	h1,
	h2 {
		text-transform: uppercase;
		text-align: center;
		font-size: 2.4rem; }

	&-empty {
		display: flex;
		padding: 2rem;
		height: 20rem;
		justify-content: center;
		align-items: center;
		background: #F9F9F9;
		box-shadow: 0 6px 1.7rem 0 rgba(3, 10, 35, 0.08);
		text-align: center;

		@media (max-width: 767px) {
			height: auto; }

		&__title {
			font-size: 2.4rem;
			font-weight: 700; }

		&__description {
			font-size: 1.4rem;
			color: #A49FB4;
			max-width: 20rem;
			margin-top: 1.2rem;
			margin-left: auto;
			margin-right: auto; } }

	&-apps {
		background: #F9F9F9;
		box-shadow: 0 6px 1.7rem 0 rgba(3, 10, 35, 0.08);
		text-align: center;
		padding: 3.2rem 6rem 5.6rem 6rem;

		@media (max-width: 767px) {
			padding: 2rem; }

		&__title {
			text-transform: uppercase;
			font-size: 2.4rem;
			margin-bottom: 3.2rem; }

		&__list {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: flex-start;
			margin-left: -4.8rem;
			margin-top: -4.8rem;

			> * {
				margin-left: 4.8rem;
				margin-top: 4.8rem; } } }

	&-app {
		width: 10rem;

		@media (max-width: 600px) {
			width: 8rem; }

		&__icon {
			height: 10rem;
			display: flex;
			justify-content: center;
			align-items: center; }

		&__title {
			text-align: center;
			margin-top: 1.2rem;
			font-size: 1.6rem;
			font-weight: 700; } }

	&-list {
		@include flex();
		justify-content: center;
		margin: -2rem 0 0 -2rem; }

	&-item {
		margin-top: 2rem;
		padding-left: 2rem;
		@include flex-width(50%); }

	&-holder {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;

		.button {
			flex: none;
			min-width: 17rem;
			margin-top: 1.5rem; } }

	&-intro {
		flex: auto 1;
		border: .2rem solid $color-4;
		padding: 1.8rem 2rem;
		position: relative;
		//min-height: 19rem
		min-width: 0;
		width: 100%;

		h4 {
			font-size: 1.5rem;
			color: $colorMain;
			text-transform: uppercase;
			letter-spacing: .2rem;
			text-align: center;
			font-weight: 700;
			line-height: 1.6; }

		.list {
			&-item {
				position: relative;
				padding-left: 1rem;

				&::before {
					@include content();
					width: .5rem;
					height: .1rem;
					background: $baseColor;
					left: 0;
					top: 1.2rem; }

				+ .list-item {
					margin-top: .2rem; } } }

		&.user-license-intro--activated {
			transition: border-color .25s, box-shadow .25s;
			position: relative;
			padding-bottom: 4.5rem;
			cursor: pointer;

			h4 {
				margin-bottom: 1rem; }

			&:hover {
				border-color: $colorMain;
				box-shadow: 0 .3rem 1.5rem rgba(#000, .2); } } }

	&-device {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: .8rem 2rem;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		text-align: center;
		color: $color-4;
		font-size: 1.4rem; }

	&-desc {
		&-row {
			@include flex();

			+ .user-license-desc-row {
				margin-top: 1.2rem; } }

		&-label {
			@include flex-width(11rem);
			text-align: right;
			font-weight: 700; }

		&-value {
			flex: 1;
			padding-left: 1.8rem; }

		&-device {
			display: flex;
			align-items: flex-start;

			span {
				min-width: 0;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;

				&:first-child {
					flex: 1; }

				+ span {
					margin-left: .9rem;
					padding-left: 1rem;
					position: relative;

					&::before {
						@include content();
						width: .1rem;
						height: 1.6rem;
						left: 0;
						top: 50%;
						margin-top: -.8rem;
						background: $baseColor; } } } }

		&-download {
			display: block;
			border: .1rem solid $color-4;
			height: 3.6rem;
			line-height: 3.4rem;
			padding: 0 1.2rem;
			margin-top: -.6rem;
			max-width: 100%;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;

			&:hover {
				color: $colorMain;
				border-color: $colorMain; } } }

	&-title {
		font-size: 2.2rem;
		font-weight: 700;
		line-height: 1.4;
		margin-bottom: 5rem; }

	&-step {
		&-title {
			font-size: 2.2rem;
			margin-bottom: 1rem; }

		.radio-list {
			margin: 1.4rem auto 0;
			max-width: 35rem; }

		.download-link {
			margin-top: 2rem; }

		+ .user-license-step {
			margin-top: 6.5rem; } }

	&-sreenshot {
		//max-width: 58.5rem
		margin: 2rem auto 0 auto;

		&.user-license-sreenshot--multiple {
			display: flex;
			margin-top: 0;
			margin-left: -2rem;
			justify-content: center;

			> div {
				padding: 2rem 0 0 2rem;

				@media (min-width: 1024px) {
					img {
						max-height: 30rem; } } } } }

	&-requirements {
		text-align: left;
		display: inline-block;
		margin-bottom: 1.5rem; }

	&-platform {
		margin: 2rem auto 0 auto;

		img {
			width: auto;
			height: 6rem; } }

	@media (max-width: 767px) {
		padding: 0;

		h1 {
			font-size: 2rem;
			text-align: center;
			text-transform: uppercase;
			font-weight: 700;
			color: $colorMain;
			margin-bottom: 1.5rem;
			letter-spacing: .2rem; }

		&-list {
			flex-wrap: nowrap;
			margin: 0;
			overflow-x: auto;
			justify-content: flex-start;
			margin-left: -2rem;
			margin-right: -2rem;
			padding-bottom: 1.5rem;
			padding-top: .5rem;

			&::before,
			&::after {
				content: '';
				display: block;
				flex: none;
				width: calc((100vw - 26.6rem) / 2); } }

		&-item {
			margin-top: 0;
			padding-left: 0;
			@include flex-width(26.6rem);

			+ .user-license-item {
				margin-left: 1.7rem; } }

		&-holder {
			padding: 1.5rem 2rem;
			background-color: #fff;
			border-radius: .7rem;
			box-shadow: 0 .5rem 1rem rgba(#000, .15);

			.button {
				margin-top: 2rem; } }

		&-intro {
			border: none;
			padding: 0;
			min-height: auto;

			h4 {
				font-size: 1.7rem;
				color: $color-1;
				text-transform: none;
				letter-spacing: 0;
				margin-bottom: 1.5rem;
				line-height: 1.4;

				span {
					font-weight: 400;
					display: block; } }

			&.user-license-intro--activated {
				padding-bottom: 0;

				h4 {
					margin-bottom: 1.5rem; }

				&:hover {
					box-shadow: none; } } }

		&-device {
			position: relative;
			padding: 0;
			margin-top: 1rem;
			text-align: left; }

		&-title {
			font-size: 1.7rem;
			font-weight: 400;
			line-height: 1.4;
			margin-bottom: 2rem; }

		&-step {
			&-holder {
				background-color: #fff;
				padding: 1.8rem 1.7rem 2.8rem 1.7rem;
				border-radius: .7rem;
				box-shadow: 0 .5rem 1rem rgba(#000, .15); }

			.radio-list {
				margin-top: 0; } } }

	@media (max-width: 413px) {
		&-desc {
			&-row {
				display: block; }

			&-label {
				max-width: none;
				text-align: left; }

			&-value {
				padding-left: 0;
				margin-top: .5rem; }

			&-device {
				span {
					&:first-child {
						flex: none; } } }

			&-download {
				margin-top: 0; } } } }

.hr {
	border: 0;
	border-top: .1rem solid $color-3;
	margin: 4.8rem 0;
	padding: 0;
	height: 0.1rem; }

.user-license-activate {
	padding: 3rem 4rem 0;
	margin-top: 3rem;
	border-top: .1rem dashed $color-4;
	text-align: center;

	@media (max-width: 767px) {
		border-top: none;
		margin-top: 1.5rem;
		padding: 0; } }

.user-license-cards {
	@include flex();
	margin-left: -2rem;
	margin-top: -2rem;

	@media (max-width: 767px) {
		display: block;
		margin-top: 0; }

	> div {
		padding-left: 2rem;
		padding-top: 2rem;

		@media (min-width: 768px) {
			@include flex-width(50%); } } }

.license-card {
	border-radius: 0.4rem;
	background-color: $color-5;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	@media (max-width: 767px) {
		background-color: $color-12; }

	&__header {
		background-color: $colorMain;
		color: $color-12;
		padding: 1rem;
		text-align: center;
		text-transform: uppercase;
		font-weight: 600;
		font-size: 1.8rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis; }

	&__body {
		padding: 1.6rem 2.4rem;
		flex: 1; }

	&__footer {
		text-align: center;
		padding: 1rem 2.4rem;
		border-top: .1rem solid $color-3; }

	&__prop + &__prop {
		margin-top: 1rem; }

	&__prop {
		display: flex;

		@media (max-width: 767px) {
			display: block; }

		&Label {
			flex: 1;
			font-weight: 600; }

		&Value {
			flex: 1; } }

	&__label {
		margin-bottom: 1rem;
		font-weight: 600; }

	.button {
		width: 100%; } }

.user-help {
	padding: 5.5rem 11rem 7rem 11rem;
	margin-top: 6rem;
	border-top: .1rem dashed $color-4;

	&-item {
		@include flex();
		align-items: center;

		+ .user-help-item {
			margin-top: 3.8rem; } }

	&-text {
		flex: 1;
		padding-right: 3rem; }

	&-title {
		font-size: 2.9rem;
		line-height: 1.2;
		font-weight: 700;
		color: $colorMain; }

	.button {
		@include flex-width(54.5%); }

	@media (max-width: 1106px) {
		padding-left: 7rem;
		padding-right: 7rem; }

	@media (max-width: 767px) {
		padding: 4rem 0;
		margin-top: 4rem;

		&-item {
			display: block;

			+ .user-help-item {
				margin-top: 3rem; } }

		&-text {
			padding-right: 0; }

		&-title {
			font-size: 2.2rem; }

		.button {
			max-width: none;
			margin-top: 2rem; } } }

.user-table {
	width: 100%;

	tr:nth-child(even) {
		td {
			background-color: #F9F9F9; } }

	th,
	td {
		border: 1px solid #A49FB4;
		padding: 0.8rem 1.2rem;

		span {
			display: none; } }

	@media (max-width: 767px) {
		display: block;

		thead {
			display: none; }

		tbody {
			display: block; }

		tr {
			display: block;
			background-color: #F9F9F9;
			box-shadow: 0 0.6rem 1.7rem 0 rgba(3, 10, 35, 0.08); }

		tr + tr {
			margin-top: 2.4rem; }

		td,
		th {
			display: block;
			border: 0;

			span {
				display: block;
				font-weight: 700; } } } }
