.drag-and-drop {
  text-align: center;
  border-radius: .5rem;
  height: 14.8rem;
  border: .1rem dashed $colorMain;
  background-color: rgba($colorMain, .04);
  padding: 1.6rem 4rem 1rem 4rem;
  cursor: pointer;
  transition: background-color .25s;

  &-file {
    margin-top: 1.5rem;
    color: $color-4; }

  .button {
    margin-top: 2rem;
    min-width: auto;
    padding-left: 1.9rem;
    padding-right: 1.9rem;

    &.button--secondary {
      background-color: #fff; } }

  &:hover {
    background-color: rgba($colorMain, .08); } }

* + .drag-and-drop {
  margin-top: 2.4rem; }
