@import '../config/base';

.faq-title {
  @media (max-width: 767px) {
    font-size: 2rem;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    color: $colorMain;
    margin-bottom: 3rem;
    letter-spacing: .2rem; } }

.faq-menu {
  display: none;

  ol {
    margin: 0;
    color: $colorMain;
    font-size: 1.8rem;
    list-style-position: inside;

    li + li {
      margin-top: 1.5rem; }

    a {
      &:hover {
        color: $colorMain; } } }

  @media (max-width: 767px) {
    display: block;
    padding-bottom: 4rem;
    border-bottom: .1rem dashed $color-4;
    margin-bottom: 4rem; } }

.faq-answers {
  &-anchor {
    position: absolute;
    left: 0;
    top: -9rem; }

  &-item {
    position: relative;

    &-title {
      font-size: 1.8rem; }

    + .faq-answers-item {
      margin-top: 3rem; } } }
