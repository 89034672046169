.radio,
.checkbox {
	overflow: hidden;
	position: relative;
	display: inline-block;
	cursor: default;

	input {
		@include hidden();

		&:checked + span::after {
			opacity: 1; }

		&:disabled + span {
			opacity: .5;
			filter: grayscale(1); } }

	span {
		display: block;
		line-height: 2.2rem;
		padding-left: 2.6rem;
		position: relative;
		font-size: 1.7rem;
		min-height: 2.2rem;

		&::before {
			@include content();
			width: 1.6rem;
			height: 1.6rem;
			border: .1rem solid $color-4;
			top: .3rem;
			left: 0; }

		&::after {
			@include content();
			width: 1.6rem;
			height: 1.6rem;
			background: url('/assets/img/check-icon.svg') no-repeat 50% 50% / 1.2rem .8949rem;
			left: 0;
			top: .3rem;
			opacity: 0;
			transition: opacity .25s; } } }

.radio {
	span {
		&::before,
		&::after {
			border-radius: 50%; }

		&::before {
			border-width: .2rem; }

		&::after {
			width: .8rem;
			height: .8rem;
			left: .4rem;
			top: .7rem;
			background: $colorMain; } }

	+ .radio {
		margin-left: 2rem; } }
