/* USERS TABLE
 * -------------------------------------------------- */

.table {
	table {
		border: .1rem solid $color-4;
		max-width: none;
		width: 80.6rem;
		line-height: 1.2;
		table-layout: fixed;

		th {
			padding: 0;
			border: .1rem solid $color-4;

			> div {
				padding: 1rem 1.2rem 1rem 1.2rem;

				&.table-check {
					padding: 0 .3rem; }

				&.table-sort {
					position: relative;
					cursor: pointer;
					padding-right: 2.5rem;

					&::before,
					&::after {
						@include content();
						width: .9rem;
						height: 1rem;
						border-color: transparent;
						border-style: solid;
						border-top-width: .5rem;
						border-bottom-width: .5rem;
						border-left-width: .45rem;
						border-right-width: .45rem;
						top: 50%;
						right: 1.2rem;
						transition: border-color .25s; }

					&::before {
						margin-top: -1.1rem;
						border-bottom-color: $color-4; }

					&::after {
						margin-top: .1rem;
						border-top-color: $color-4; }

					&:hover {
						&::before {
							border-bottom-color: $color-1; }

						&::after {
							border-top-color: $color-1; } }

					&.table-sort--top {
						&::before {
							border-bottom-color: $color-1;
							margin-top: -.75rem; }

						&::after {
							display: none; } }

					&.table-sort--bottom {
						&::before {
							display: none; }

						&::after {
							border-top-color: $color-1;
							margin-top: -.25rem; } } } } }

		td {
			padding: 1.3rem 1.2rem;
			overflow: hidden; }

		tr {
			&:nth-child(odd) {
				td {
					background: $color-5; } }

			+ tr {
				td {
					border-top: .1rem solid $color-4; } } } }

	&-break {
		word-break: break-all; }

	&-nowrap {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 100%;

		&:hover {
			white-space: normal;
			text-overflow: clip;
			word-break: break-all; } }

	.list {
		max-width: 100%;

		&-item + .list-item {
			margin-top: 1.4rem; }

		&.list--nomargin {
			.list-item + .list-item {
				margin-top: 0; }

			.table-break {
				padding: .2rem 0;
				line-height: 1.6rem; } } }

	&-chain {
		a {
			display: inline-block;

			svg {
				display: block;
				height: 2rem;
				width: 2rem;
				fill: $colorMain;
				transition: fill .25s; }

			&:hover {
				svg {
					fill: $color-7; } } } }

	&-delete {
		text-align: center;

		button {
			display: inline-block;
			vertical-align: top;
			border: none;
			background: none;
			box-shadow: none;
			border-radius: 0;
			font-size: 0;
			pointer-events: auto;

			svg {
				display: block;
				fill: $color-4;
				transition: fill .25s; }

			&:hover {
				svg {
					fill: $colorMain; } } } }

	@media (max-width: 1105px) {
		margin-right: -1rem;
		padding-right: 1rem;
		overflow-x: auto;
		display: flex;

		table {
			@include flex-width(80.6rem); }

		&::after {
			content: '';
			@include flex-width(1rem); } } }

.table-footer {
	@include flex();
	align-items: center;
	justify-content: space-between;
	margin-top: 2rem;

	&-item--view {
		@include flex-width(14rem); }

	.group-actions {
		margin-top: 0; }

	.pirogov-container {
		.pirogov__menu {
			top: auto;
			bottom: 100%;
			margin-top: 0;
			margin-bottom: -.1rem; }

		.pirogov__control--menu-is-open + .pirogov-select-placeholder {
			visibility: hidden; } } }
