.file {
	position: relative;
	overflow: hidden;
	display: inline-block;
	vertical-align: top;
	margin: 1rem;

	input {
		@include hidden(); }

	label {
		display: block;
		position: relative;
		cursor: pointer;

		span {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: block;
			max-width: 18.5rem; } } }
