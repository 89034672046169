@import '../config/base';

@keyframes fadeIn {
	0% {
		opacity: 0; }

	100% {
		opacity: 1; } }

.form-license-pack {
	margin-top: 3rem;

	&.form-license-pack--disabled {
		pointer-events: none;
		opacity: .5;
		filter: grayscale(1);

		* {
			pointer-events: none; } }

	&__item {
		&.form-license-pack__item--hide {
			h4.form-license-pack__title {
				&::before {
					transform: translate(-25%, 25%) rotate(-135deg); } }

			.form-license-pack__separator {
				opacity: 1; }

			.form-license-pack__count {
				opacity: 1; }


			.form-license-pack__body {
				display: none;
				opacity: 0; } }

		&.form-license-pack__item--disabled {
			pointer-events: none;
			opacity: .5;
			filter: grayscale(1);

			* {
				pointer-events: none; } }

		+ .form-license-pack__item {
			margin-top: 3rem; } }

	&__heading {
		@include flex();
		align-items: center;
		cursor: pointer;

		&:hover {
			h4.form-license-pack__title {
				color: $colorMain2;

				&::before {
					border-color: $colorMain2; } } } }

	h4.form-license-pack__title {
		margin-bottom: 0;
		text-align: left;
		font-size: 1.6rem;
		line-height: 1.8rem;
		letter-spacing: .032rem;
		font-weight: 700;
		flex: none;
		position: relative;
		padding-left: 2.4rem;
		transition: color .25s;

		&::before {
			@include content();
			width: 1rem;
			height: 1rem;
			border-left: .2rem solid $colorMain;
			border-bottom: .2rem solid $colorMain;
			top: 50%;
			margin-top: -.75rem;
			left: .2rem;
			transform: translate(0, 0) rotate(-45deg);
			backface-visibility: hidden;
			transition: transform .25s, border-color .25s; } }

	&__separator {
		flex: 1;
		height: .1rem;
		margin-left: 1rem;
		margin-right: 1rem;
		background-color: $color-6;
		opacity: 0;
		transition: opacity .25s; }

	&__count {
		line-height: 1.8rem;
		font-size: 1.4rem;
		color: $color-4;
		flex: none;
		opacity: 0;
		transition: opacity .25s; }

	&__body {
		display: block;
 }		//animation: fadeIn .25s forwards

	&__group {
		margin-top: 2.2rem;

		&-item {
			&-heading {
				margin: 0 -4rem;
				position: relative;
				text-align: center;
				line-height: 0;
				margin-bottom: 3rem;

				&::before {
					@include content();
					width: 100%;
					height: .1rem;
					background-color: $color-6;
					left: 0;
					top: 50%;
					transition: background-color .25s; }

				span {
					display: inline-block;
					line-height: 2rem;
					padding: 0 1rem;
					background-color: #FFFFFF;
					color: $color-4;
					font-size: 1.4rem;
					position: relative;
					vertical-align: top; } }

			&-delete {
				position: absolute;
				right: 3rem;
				line-height: 2rem;
				padding: 0 1rem;
				background-color: #FFFFFF;
				color: $colorMain;
				font-size: 1.4rem;
				top: 0;
				cursor: pointer;

				&:hover {
					color: $colorMain2; } }

			+ .form-license-pack__group-item {
				margin-top: 3rem; } } }

	&__create {
		margin: 2.6rem -4rem 0 -4rem;
		cursor: pointer;
		position: relative;
		text-align: center;
		line-height: 0;

		&::before {
			@include content();
			width: 100%;
			height: .1rem;
			background-color: $color-6;
			left: 0;
			top: 50%;
			transition: background-color .25s; }

		span {
			display: inline-block;
			line-height: 2rem;
			padding: 0 1rem;
			background-color: #FFFFFF;
			color: $colorMain;
			font-size: 1.4rem;
			position: relative;
			vertical-align: top; }

		&:hover {
			&::before {
				background-color: $colorMain; } } }

	+ .form-controls {
		margin-top: 3rem; } }
