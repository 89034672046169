.success-message {
  text-align: center;
  border-radius: .5rem;
  border: .1rem solid $color-8;
  background-color: #F3F4F2;
  padding: 1.6rem 4rem;
  width: 100%;
  max-width: 35.4rem;
  margin: 0 auto;
  color: $color-8;

  + .login {
    margin-top: 3rem; } }
