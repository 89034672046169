.download-link {
  display: inline-block;
  border: .1rem solid $color-4;
  height: 3.6rem;
  line-height: 3.4rem;
  padding: 0 1.2rem 0 5.2rem;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  color: $colorMain;
  vertical-align: top;

  &::before,
  &::after {
    @include content();
    left: 0;
    height: 100%;
    top: 0; }

  &::before {
    border-right: .1rem solid $color-4;
    background: $color-5;
    width: 3.9rem; }

  &::after {
    width: 3.8rem;
    background: url('/assets/img/chain.svg') no-repeat 50% 50% / 2rem 2rem; } }
