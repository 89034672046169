.control-circle {
	display: block;
	width: 2.6rem;
	height: 2.6rem;
	border-radius: 50%;
	border: .2rem solid $colorMain;
	font-size: 0;
	position: relative;
	background: none;
	box-shadow: none;
	padding: 0;
	transition: background-color .25s, border-color .25s;

	&.control-circle--minus {
		&::before {
			@include content();
			width: 1.2rem;
			height: .2rem;
			background-color: $colorMain;
			top: 50%;
			left: 50%;
			margin: -.1rem 0 0 -.6rem;
			transition: background-color .25s; } }

	&.control-circle--plus {
		&::before,
		&::after {
			@include content();
			width: 1.2rem;
			height: .2rem;
			background-color: $colorMain;
			top: 50%;
			left: 50%;
			margin: -.1rem 0 0 -.6rem;
			transition: background-color .25s; }

		&::after {
			transform: rotate(90deg);
			backface-visibility: hidden; } }

	&:hover {
		border-color: $colorMain2;
		background-color: $colorMain2;

		&::before,
		&::after {
			background-color: #fff; } }

	&:disabled {
		border-color: $color-4;
		background-color: #fff;

		&::before,
		&::after {
			background-color: $color-4; } }

	+ .control-circle {
		margin-left: 1rem; } }
