.list--pagination {
	justify-content: center;
	margin-top: 4rem;
	@include flex();

	.list-item {
		.list-link {
			width: 3.6rem;
			height: 3.6rem;
			border: .1rem solid $color-3;
			line-height: 3.4rem;
			text-align: center;
			color: $colorMain;
			transition: background-color .25s, color .25s;

			&:hover {
				background-color: $color-5; } }

		&.list-item--active {
			.list-link {
				background-color: $colorMain;
				color: $color-5; } }

		&.list-item--start,
		&.list-item--end {
			.list-link {
				font-size: 0;
				position: relative;

				&::before {
					@include content();
					width: .9545rem;
					height: 1rem;
					background: url('/assets/img/arrow-double.svg') no-repeat 50% 50% / 100% 100%;
					top: 50%;
					left: 50%;
					transform: translate(-50% , -50%); } } }

		&.list-item--start {
			.list-link {
				&::before {
					transform: translate(-50% , -50%) rotate(180deg); } } }

		&.list-item--prev,
		&.list-item--next {
			.list-link {
				font-size: 0;
				position: relative;

				&::before {
					@include content();
					width: .8333rem;
					height: 1rem;
					background: url('/assets/img/arrow.svg') no-repeat 50% 50% / 100% 100%;
					top: 50%;
					left: 50%;
					transform: translate(-50% , -50%); } } }

		&.list-item--prev {
			.list-link {
				&::before {
					transform: translate(-50% , -50%) rotate(180deg); } } } } }


@media (max-width: 374px) {
	.page-user {
		.list--pagination {
			margin-top: 3rem;

			.list-item {
				.list-link {
					width: 3rem;
					height: 3rem;
					line-height: 2.8rem;
					font-size: 1.5rem; }

				&.list-item--start,
				&.list-item--end,
				&.list-item--prev,
				&.list-item--next {
					.list-link {
						font-size: 0; } } } } } }
