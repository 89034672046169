@import '../config/base';

.support-heading {
	@include flex();
	justify-content: space-between;
	align-items: center;

	&-status {
		.task-status {
			font-size: 1.7rem; } } }

.support-info {
	background-color: $color-3;
	padding: 1rem 2rem;
	margin-top: 2.5rem;

	.list {
		margin-left: -1.5rem;
		display: flex;
		justify-content: space-between;

		&-item {
			padding-left: 1.5rem;
			flex: none;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			color: $color-4;
			min-width: 0;
			max-width: 28%;

			span {
				color: $color-1; }

			div {
				display: inline; } } }

	@media (max-width: 1105px) {
		.list {
			flex-wrap: wrap;
			margin-top: -.5rem;

			&-item {
				@include flex-width(50%);
				margin-top: .5rem; } } } }

.support-title {
	font-size: 1.8rem;
	font-weight: 700;
	margin-top: 2rem;
	margin-bottom: 0; }

.support-data {
	margin-top: 2.5rem;

	.list {
		margin-top: -2rem;

		&-item {
			margin-top: 2rem;

			&-label {
				color: $color-4; } }

		&.list--row {
			display: flex;
			margin-left: -1.5rem;
			justify-content: space-between;

			.list-item {
				flex: none;
				flex: none;
				max-width: 25%;
				padding-left: 1.5rem;

				&-value {
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden; } } }

		+ .list {
			margin-top: 2rem; } } }

.support-gallery {
	@include flex();
	margin-left: -1rem;

	&-item {
		flex: none;
		margin: 1rem 0 0 1rem;

		button {
			display: block;
			border: none;
			background: none;
			border-radius: 0;
			padding: 0;
			margin: 0; }

		img {
			display: block;
			height: 6.4rem;
			width: auto; } } }

.support-comments {
	margin-top: 4.5rem;

	&-title {
		color: $color-4; }

	&-list {
		margin-top: 1.2rem; }

	&-item {
		@include flex();
		padding: 0 1rem;
		margin: 0 -1rem;
		transition: background-color .25s;
		position: relative;

		&:last-child {
			.support-comments-userpic {
				padding-bottom: 1rem;
				&::before {
					display: none; } } }

		&:hover {
			background-color: $color-3;

			.support-comments-control {
				opacity: 1; } } }

	&-userpic {
		@include flex-width(4.2rem);
		padding-bottom: 3rem;
		padding-top: .7rem;
		position: relative;

		&::before {
			@include content();
			width: .2rem;
			bottom: 0;
			top: 5.6rem;
			left: 50%;
			margin-left: -.1rem;
			background-color: $color-4; }

		&-image {
			width: 4.2rem;
			height: 4.2rem;
			overflow: hidden;
			background: url('/assets/img/userpic.svg') no-repeat 50% 50% / 100% 100%;

			img {
				display: block;
				border-radius: 50%; } } }

	&-message {
		flex: 1;
		padding-left: 1.6rem;
		padding-top: .5rem;
		padding-bottom: 1rem;

		p {
			font-size: 1.5rem;
			line-height: 1.2; } }

	&-heading {
		@include flex();
		margin-bottom: .2rem; }

	&-name {
		flex: none;
		max-width: calc(100% - 31rem);
		color: $colorMain;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis; }

	&-date {
		//+flex-width(24rem)
		padding-left: 1rem;
		color: $color-4; }

	&-control {
		display: block;
		position: absolute;
		width: 1.6rem;
		height: 1.6rem;
		border-radius: 0;
		background: none;
		border: none;
		padding: 0;
		margin: 0;
		top: 1rem;
		opacity: 0;

		svg {
			display: block;
			margin: 0 auto;
			fill: $colorMain;
			transition: fill .25s; }

		&:hover {
			svg {
				fill: $color-7; } }

		&.support-comments-control--edit {
			right: 3.8rem; }

		&.support-comments-control--delete {
			right: 1rem; } }

	&-form {
		margin-top: 1.5rem; } }

@media (max-width: 599px) {
	.page-user {
		.support-heading {
			display: block;

			&-status {
				line-height: 1;

				.task-status {
					vertical-align: top; } }

			&-new {
				margin-top: 2rem; } }

		.support-info {
			.list {
				display: block;
				margin-left: 0;

				&-item {
					max-width: none;
					padding-left: 0; } } }

		.support-data {
			.list {
				&.list--row {
					display: block;
					margin-left: 0;

					.list-item {
						max-width: none;
						padding-left: 0; } } } }

		.support-comments {
			&-heading {
				display: block; }

			&-name {
				max-width: calc(100% - 6rem); }

			&-date {
				max-width: none;
				padding-left: 0; } } } }

@media (max-width: 413px) {
	.page-user {
		.support-comments {
			&-date {
				font-size: 0;

				span {
					font-size: 1.5rem; } } } } }

@media (max-width: 374px) {
	.page-user {
		.support-comments {
			&-name {
				max-width: calc(100% - 5rem); } } } }
