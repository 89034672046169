/* BUTTONS
 * -------------------------------------------------- */

.button {
	display: inline-block;
	box-shadow: none;
	min-width: 10rem;
	text-align: center;
	color: #FFF;
	line-height: 2rem;
	font-size: 1.7rem;
	padding: 1.2rem 3.2rem;
	background: $colorMain;
	border: .1rem solid $colorMain;
	border-radius: 3rem;
	transition: color .25s, background .25s, border-color .25s;

	svg {
		vertical-align: top;
		display: inline-block;
		margin-right: .9rem; }

	&:hover,
	&:active {
		background: $colorMain2;
		border-color: $colorMain2; }

	&[disabled] {
		background: $color-6;
		border-color: $color-6;
		color: $color-4; }

	&.button-ok {
		width: 9.2rem;
		min-width: auto; }

	&.button--primary {
		padding: .7rem 3.4rem;
		border-radius: .5rem;

		svg {
			margin-top: .2rem;
			fill: #FFF; } }

	&.button--secondary {
		padding: .7rem 3.4rem;
		border-radius: .5rem;
		background: transparent;
		color: $colorMain;

		svg {
			margin-top: .2rem;
			fill: $colorMain; }

		&:hover,
		&:active {
			background: transparent;
			color: $colorMain2;

			svg {
				fill: $colorMain2; } }

		&[disabled] {
			background: transparent;
			border-color: $color-4;

			svg {
				fill: $color-4; } } } }

.button-row {
	margin: -1.6rem 0 0 -1.6rem;

	.button {
		margin-top: 1.6rem;
		margin-left: 1.6rem; }

	&.button-row--options {
		.button {
			min-width: 13.8rem; } }

	&.button-row--right {
		text-align: right; }

	&.button-row--add {
		margin-bottom: 3rem;

		.button {
			min-width: 20rem; } }

	&.button-row--support {
		margin-bottom: 2rem; } }

.button-link {
	border-radius: 0;
	background: none;
	border: none;
	margin: 0;
	padding: 0;
	display: inline;
	vertical-align: top;
	box-shadow: none;
	text-align: left;
	font-size: inherit;
	line-height: inherit;
	color: $colorMain;
	transition: color .25s;

	&:hover {
		color: $color-7; } }
