.field {
	position: relative;
	outline: none;

	&-input {
		width: 100%;
		border: .1rem solid $color-4;
		border-radius: 0;
		height: 3.6rem;
		padding: 0 1.2rem;
		color: $color-1;
		appearance: none;
		font-size: 1.6rem;
		display: block;
		line-height: 2.4rem;
		background-color: #fff;
		box-shadow: 0 0 0 3rem white inset !important;

		&[type="number"] {
			-moz-appearance: textfield;

			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0; } }

		//&:-webkit-autofill,
		//&:-webkit-autofill:hover,
		//&:-webkit-autofill:focus,
		//&:-webkit-autofill:active
		//	transition: background-color 36000s ease-in-out 0s

		&:focus {
			border-color: $color-1;

			+ .field-label {
				transform: translate3d(.6rem, -1.75rem, 0) scale(0.875); } } }

	textarea.field-input {
		height: 11.4rem;
		padding: .5rem 1.2rem; }

	&-label {
		position: absolute;
		padding: 0 .6rem;
		left: .7rem;
		top: .8rem;
		max-width: calc(100% - 1.4rem);
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		color: $color-4;
		font-size: 1.6rem;
		height: 2rem;
		line-height: 2rem;
		background-color: #fff;
		transform: translate3d(0, 0, 0) scale(1);
		transition: transform .15s linear;
		backface-visibility: hidden;
		will-change: transform;
		cursor: text;
		transform-origin: center left; }

	&-search {
		display: block;
		border: none;
		box-shadow: none;
		padding: 0;
		margin: 0;
		background: none;
		border-radius: 0;
		height: 100%;
		width: 2.1rem;
		position: absolute;
		top: 0;
		right: 1rem;
		@include flex();
		align-items: center;

		svg {
			fill: $color-4;
			display: block;
			transition: fill .25s; }

		&:hover {
			svg {
				fill: $color-1; } } }

	&-toggle-password {
		width: 2rem;
		height: 2rem;
		padding: 0;
		margin: -1rem 0 0 0;
		position: absolute;
		right: 1rem;
		top: 50%;
		border: 0;
		border-radius: 0;
		cursor: pointer;
		background-color: transparent;
		background-position: center;
		background-repeat: no-repeat;
		background-size: 100% auto;

		&.field-toggle-password_hide {
			background-image: url("/assets/img/eye-on.svg"); }

		&.field-toggle-password_show {
			background-image: url("/assets/img/eye-off.svg"); } }

	&.field--password {
		position: relative;

		.field-input {
			padding-right: 3.6rem; } }

	&.field--search {
		position: relative;

		.field-input {
			padding-right: 3.6rem; }

		.field-label {
			max-width: calc(100% - 3.8rem); } }

	&.field--calendar {
		.field-input {
			//background: url('../img/calendar.svg') no-repeat right 1rem top 50% / 2.16rem 2.4rem
			padding-right: 3.8rem;
			cursor: default; }

		.field-label {
			max-width: calc(100% - 4rem);
			z-index: 0; }

		.react-datepicker-popper {
			top: 100% !important;
			left: auto !important;
			right: 0;
			transform: none !important;

			&[data-placement^="top"] {
				top: auto !important;
				bottom: 100% !important; } }

		.react-datepicker {
 }			//left: calc(-100% + 3.16rem)

		.react-datepicker__triangle {
			left: calc(100% - 2.08rem) !important; }

		.react-datepicker-wrapper {
			display: block;
			width: 2.16rem;
			height: 2.4rem;
			position: absolute;
			z-index: 1;
			top: 50%;
			right: 1rem;
			margin-top: -1.2rem;

			button {
				width: 2.16rem;
				height: 2.4rem;
				padding: 0;
				background: url('/assets/img/calendar.svg') no-repeat 0 0 / 100% 100%;
				display: block;
				font-size: 0;
				margin: 0;
				border: 0;
				border-radius: 0;
				cursor: pointer;
				transition: opacity .25s ease;

				&:hover {
					opacity: .7; } }

			.react-datepicker__input-container {
				z-index: 1;
				position: static;
				display: block; }

			input {
				width: 100%;
				border: .1rem solid $color-4;
				border-radius: 0;
				height: 3.6rem;
				padding: 0 3.8rem 0 1.2rem;
				color: $color-1;
				box-shadow: none;
				appearance: none;
				font-size: 1.6rem;
				display: block;
				line-height: 2.4rem;
				background: #fff url('/assets/img/calendar.svg') no-repeat right 1rem top 50% / 2.16rem 2.4rem; } }

		.react-datepicker-popper {
			z-index: 10; }

		&.field--notempty {
			.field-label {
				z-index: 1; } } }

	&.field--textarea-small {
		textarea.field-input {
			height: 8.5rem; } }

	&.field--textarea-middle {
		textarea.field-input {
			height: 9.4rem; } }

	&.field--textarea-big {
		textarea.field-input {
			height: 18rem; } }

	&.field--textarea-large {
		textarea.field-input {
			height: 26rem; } }

	&.field--error {
		.field-input {
			border-color: $colorMain;
			color: $colorMain; }

		.field-label {
			color: $colorMain; } }

	&.field--required {
		position: relative;

		&::before {
			position: absolute;
			display: block;
			content: '*';
			color: $colorMain;
			font-size: 2.4rem;
			font-weight: 700;
			line-height: 1;
			right: -1.2rem;
			top: -.3rem; } }

	&.field--tooltip {
		position: relative;

		.tooltip {
			position: absolute;
			top: 50%;
			margin-top: -.8rem;
			right: -2.5rem;
			cursor: pointer;
			cursor: help;
			pointer-events: none;

			svg {
				fill: $color-4;
				transition: fill .25s; }

			&:hover {
				svg {
					fill: $colorMain; } } } }

	&.field--disabled {
		opacity: .5;
		filter: grayscale(1); }

	&.field--notempty {
		.field-label {
			transform: translate3d(.6rem, -1.75rem, 0) scale(0.875); } } }
