.form {
	&-headnote {
		color: $color-4;
		padding-bottom: .5rem; }

	&-item {
		&.form-item--calendar {
			@include flex();

			.field {
				flex: 1; } }

		&.form-item--checkbox-center {
			padding-top: .7rem;

			.checkbox {
				vertical-align: top; } }

		&.form-item--circle-control {
			position: relative;
			padding-right: 3.5rem;

			.control-circle {
				position: absolute;
				right: 0;
				top: .5rem; } }

		&.form-item--search + .form-item {
			margin-top: 1rem; }

		&--link {
			text-align: right;

			a {
				display: inline-block;
				vertical-align: top;
				margin-top: -.2rem;
				font-size: 1.6rem;
				border-bottom: .1rem solid currentColor;
				line-height: 1.2;

				&:hover {
					border-bottom-color: rgba(#FFF, 0); } } }

		+ .form-item {
			margin-top: 2rem;

			&.form-item--checkbox {
				margin-top: 1.4rem; } }

		+ .form-row {
			margin-top: 0; } }

	&-delimiter {
		@include flex-width(3.6rem);
		text-align: center;
		line-height: 3.6rem;
		color: $color-4; }

	&-title {
		font-weight: 700;
		margin-bottom: 2rem; }

	* + .form-title {
		margin-top: 4rem; }

	&-row {
		@include flex();
		margin: -2rem 0 0 -4rem;

		+ .form-item {
			margin-top: 2rem; }

		+ .form-row {
			margin-top: 0; } }

	&-col {
		@include flex-width(50%);
		margin-top: 2rem;
		padding-left: 4rem;

		&.form-col-33 {
			@include flex-width(33.33%); }

		&.form-col-39 {
			@include flex-width(39%); }

		&.form-col-22 {
			@include flex-width(22%); } }

	&-group {
		&-title {
			margin-bottom: .6rem;
			@include flex();
			justify-content: space-between;

			button {
				padding: 0;
				border: none;
				border-radius: 0;
				background: none;
				color: $colorMain;
				transition: color .25s;

				&:hover {
					color: $color-7; } } } }

	* + .form-group {
		margin-top: 2.5rem; }

	&-file {
		.field {
			@include flex();

			input {
				@include hidden(); }

			&-input {
				flex: 1; }

			&-label {
				max-width: calc(100% - 15.4rem); } }

		.button {
			flex: none;
			min-width: 13.8rem;
			margin-left: -.3rem;
			padding-left: 1.9rem;
			padding-right: 1.9rem;
			z-index: 1;
			cursor: pointer;

			&.button--secondary {
				background-color: #fff; } }

		.list {
			flex: none;
			width: 100%;
			margin-top: 1.2rem;
			color: $color-4;

			&-item {
				position: relative;

				&-remove {
					font-size: 0;
					width: 2rem;
					height: 2rem;
					display: block;
					position: absolute;
					top: 0;
					left: -.5rem;
					padding: 0;
					border: none;
					border-radius: 0;
					background: none;

					&::before,
					&::after {
						@include content();
						width: 1.2rem;
						height: .2rem;
						background-color: $color-4;
						top: 50%;
						left: 50%;
						margin: .1rem 0 0 -.6rem;
						transition: background-color .25s;
						backface-visibility: hidden; }

					&::before {
						transform: rotate(-45deg); }

					&::after {
						transform: rotate(45deg); }

					&:hover {
						&::before,
						&::after {
							background-color: $colorMain; } } }

				span {
					display: block;
					padding-left: 1.8rem;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap; }

				+ .list-item {
					margin-top: .8rem; } } }

		&.form-file--required {
			position: relative;

			&::before {
				position: absolute;
				display: block;
				content: '*';
				color: $colorMain;
				font-size: 2.4rem;
				font-weight: 700;
				line-height: 1;
				right: -1.2rem;
				top: -.3rem; } } }

	&-warning {
		padding-left: 2.8rem;
		background: url('/assets/img/form-warning.svg') no-repeat 0 .3rem / 1.6rem 1.6rem; }

	* + .form-warning {
		margin-top: 2rem; }

	&-error {
		display: block;
		line-height: 1.8rem;
		margin-top: .6rem;
		color: $colorMain;
		font-size: 1.4rem;
		padding-left: 2.4rem;
		background: url('/assets/img/form-error.svg') no-repeat 0 .2rem / 1.4rem 1.4rem; }

	&-controls {
		@include flex();
		margin: -1rem 0 0 -3rem;

		.button {
			margin: 1rem 0 0 3rem;
			min-width: 13.8rem;
			padding-left: 3rem;
			padding-right: 3rem; }

		&.form-controls--right {
			justify-content: flex-end;

			.button {
				&.button--add-group {
					margin-right: auto; } } }

		&.form-controls--justify {
			justify-content: space-between;
			flex-wrap: nowrap;

			.button {
				flex: 1;
				min-width: auto;

				&.button--add-group {
					margin-right: auto; } } }

		&.form-controls--fit-buttons {
			.button {
				padding-left: 2rem;
				padding-right: 2rem; } } }

	* + .form-controls {
		margin-top: 1rem;

		&.form-controls--right {
			margin-top: 2rem; }

		&.form-controls--margin {
			margin-top: 2rem; } }

	&-button-fullwidth {
		flex: 1; }

	&-progress {
		@include flex();

		&-line {
			flex: 1;
			height: 1.6rem;
			background-color: $color-3;

			> div {
				height: 100%;
				background-color: $colorMain; } }

		&-percent {
			@include flex-width(4rem);
			text-align: right;
			height: 1.6rem;
			line-height: 1.6rem;
			color: $color-4; }

		+ .form-controls {
			margin-top: 2.5rem;

			&.form-controls--right {
				margin-top: 2.5rem; } } }

	&-user-controls {
		margin-top: -1rem;

		div + div {
			margin-top: .3rem; }

		+ .form-license {
			margin-top: 4rem; } }

	&-license {
		+ .form-license {
			margin-top: 2rem;
			padding-top: 2rem;
			border-top: 1px solid $color-3; }

		&-label {
			line-height: 1.2;
			margin-bottom: 1rem; }

		&-add {
			@include flex();

			.select,
			.pirogov-container {
				flex: 1; }

			.button {
				padding-left: 2rem;
				padding-right: 2rem;
				flex: none;
				margin-left: 1rem; } }

		&-empty {
			background: url('/assets/img/form-warning.svg') no-repeat 0 .3rem / 1.6rem 1.6rem;
			padding-left: 2.6rem;
			margin-top: 1.8rem; }

		&-list {
			margin-top: 1.4rem; }

		&-item {
			&.form-license-item--short {
				.form-license-params {
					display: none; }

				.form-license-toggler {
					transform: rotate(45deg);
					top: 50%;
					margin-top: -.6rem;
					right: 1.3rem; } }

			+ .form-license-item {
				margin-top: 1.2rem; } }

		&-holder {
			border: .1rem solid $color-4;
			padding: 1.5rem;
			background-color: $color-5;
			position: relative; }

		&-toggler {
			width: 1.2rem;
			height: 1.2rem;
			border-left: .2rem solid $color-4;
			border-bottom: .2rem solid $color-4;
			top: 1.4rem;
			right: 1.6rem;
			transform: rotate(-45deg);
			backface-visibility: hidden;
			transition: border-color .25s;
			z-index: 1;
			position: absolute;
			cursor: pointer;

			&:hover {
				border-color: $colorMain; } }

		&-title {
			font-weight: 700;
			line-height: 2.2rem;
			padding-right: 5rem;
			background: url('/assets/img/org-title.svg') no-repeat 0 .1rem / 1.6rem 1.8678rem;
			padding-left: 3rem;
			margin-top: -.2rem;
			margin-left: -.2rem;
			cursor: pointer; }

		&-params {
			margin-top: 1.4rem;

			.list {
				&-item {
					@include flex();

					&-label {
						@include flex-width(10rem);
						font-weight: 700;
 }						//text-align: right

					&-value {
						flex: 1;
						padding-left: 1rem;

						textarea.field-input {
							width: 100%;
							padding: .2rem 1rem;
							margin-top: -.3rem;
							height: 9.4rem;
							background-color: #fff; }

						input.field-input {
							background-color: #fff;
							width: 100%;
							margin-top: -.6rem; } }

					+ .list-item {
						margin-top: .8rem; } } } }

		&-delete {
			text-align: right;
			padding-top: .3rem; }

		&-controls {
			@include flex();
			margin: -1rem 0 0 -2rem;
			justify-content: space-between;

			button {
				font-size: 0;
				white-space: nowrap;
				margin: 2rem 0 0 2rem;

				svg {
					display: inline-block;
					vertical-align: middle;
					margin-right: .6rem;
					fill: currentColor; }

				span {
					font-size: 1.7rem;
					display: inline-block;
					vertical-align: middle; } } } }

	&.form--user {
		padding-top: 1rem;

		.form-row {
			margin-left: -6rem; }

		.form-col {
			padding-left: 6rem; }

		.form-item + .form-item {
			margin-top: 3rem; }

		.form-controls {
			margin-top: 2rem; } }

	&.form--update {
		.field--calendar {
			width: 18rem; }

		.form-controls {
			margin-top: 2rem; } }

	* + .form-progress {
		margin-top: 2rem;

		.form-controls {
			.button {
				@include flex-width(calc(50% - 3.5rem)); } } } }

.page-user {
	@media (max-width: 599px) {
		.form {
			&-row {
				display: block;
				margin: 0; }

			&-col {
				@include flex-width(50%);
				max-width: none;
				margin-top: 0;
				padding-left: 0;

				+ .form-col {
					margin-top: 2rem; } } } } }
