.radio-list {
  label {
    display: block;
    cursor: pointer;

    input {
      @include hidden();

      &:checked {
        + span {
          &::after {
            opacity: 1; } } } }

    span {
      position: relative;
      display: block;
      padding: .7rem 6.2rem .7rem 2.4rem;
      line-height: 1.2;
      font-size: 2.2rem;
      font-weight: 700;
      background: $color-3;
      text-align: left;

      &::before,
      &::after {
        @include content();
        border-radius: 50%;
        top: 50%; }

      &::before {
        width: 1.8rem;
        height: 1.8rem;
        background-color: #fff;
        margin-top: -.9rem;
        right: 2.2rem; }

      &::after {
        width: .8rem;
        height: .8rem;
        background-color: $colorMain;
        margin-top: -.4rem;
        right: 2.7rem;
        opacity: 0;
        transition: opacity .25s; } }

    + label {
      margin-top: .2rem; } } }
