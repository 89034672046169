.notification {
	position: fixed;
	padding-bottom: 4rem;
	width: 100%;
	height: 100%;
	left: 100%;
	top: 0;
	@include flex();
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-end;
	z-index: 2000;

	&-item {
		max-width: 51.2rem;
		flex: none;
		transform: translateX(0);
		backface-visibility: hidden;
		padding: 1.4rem 10rem 3rem 6.4rem;
		background-color: $color-3;
		box-shadow: 0 .3rem .6rem #D6D6D6;
		position: relative;
		background-repeat: no-repeat;
		background-position: 1.6rem 50%;
		background-size: 3rem 3rem;

		&.notification-item--warning {
			background-image: url('/assets/img/note-warning.svg'); }

		&.notification-item--info {
			background-image: url('/assets/img/note-info.svg'); }

		&.notification-item--show {
			transform: translateX(-100%); }

		+ .notification-item {
			margin-top: 1.2rem; } }

	&-close {
		position: absolute;
		top: 2rem;
		right: 2.7rem;
		height: 2rem;
		width: 2rem;
		font-size: 0;
		border: none;
		background: none;
		box-shadow: none;
		box-shadow:  none;

		&::before,
		&::after {
			@include content();
			width: 2rem;
			height: .4rem;
			background-color: $color-4;
			left: 0;
			top: 50%;
			margin-top: -.2rem;
			backface-visibility: hidden;
			transition: background-color .25s; }

		&::before {
			transform: rotate(45deg); }

		&::after {
			transform: rotate(-45deg); }

		&:hover {
			&::before,
			&::after {
				background-color: $colorMain; } } }

	&-content {}

	&-title {
		margin-bottom: .9rem; } }

.page-user {
	@media (max-width: 767px) {
		~ .notification {
			padding-bottom: 0;

			.notification-item {
				padding-right: 4rem;
				padding-bottom: 1.4rem; }

			.notification-close {
				top: 1rem;
				right: 1rem; } } } }
