.sorting {
  @include flex();
  margin-bottom: 2rem;

  &-title {
    flex: none;
    padding-right: 1.6rem; }

  &-list {
    flex: 1; }

  .list {
    @include flex();
    margin: -1rem 0 0 -1rem;

    &-item {
      flex: none;
      margin: 1rem 0 0 2.7rem;
      position: relative;
      cursor: pointer;

      &::before {
        @include content();
        width: 1.2rem;
        height: 1.2rem;
        border: .6rem solid transparent;
        top: 50%;
        left: -1.7rem;
        transition: border-color .25s, margin-top .25s; }


      span {
        display: block;
        color: $colorMain;
        border-bottom: .1rem solid $colorMain;
        transition: border-color .25s;
        line-height: 1.2; }

      &--up,
      &--down {
        padding-left: 1.7rem;
        margin-left: 1rem;

        &::before {
          left: 0; } }

      &--up {
        &::before {
          border-bottom-color: $colorMain;
          margin-top: -.8rem; } }

      &--down {
        &::before {
          border-top-color: $colorMain;
          margin-top: -.2rem; } }

      &:hover {
        span {
          border-bottom-color: rgba(#FFF, 0); } } } } }
