@import '../config/base';

.organization-menu {
  .list {
    @include flex();
    margin: -2rem 0 0 -5rem;
    max-width: 79.4rem;

    &-item {
      @include flex-width(50%);
      margin-top: 2rem;
      padding-left: 5rem;

      &-icon {
        @include flex-width(4.5rem);
        background-color: $colorMain;
        @include flex();
        align-items: center;
        justify-content: center;

        img {
          display: block;
          flex: none; } }

      &-title {
        background-color: $color-3;
        color: $colorMain;
        padding: 0 1.4rem;
        @include flex();
        align-items: center;
        transition: background-color .25s, color .25s;

        flex: 1;
        margin-left: .2rem;

        strong {
          flex: none;
          line-height: 1.2; } } }

    &-link {
      @include flex();
      height: 4.5rem;

      &:hover {
        .list-item-title {
          color: $color-3;
          background-color: $colorMain; } } } } }
