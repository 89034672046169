.account {
  display: flex;
  align-items: center;
  flex: none;
  margin-left: auto;
  width: 21.6rem;
  justify-content: flex-end;

  > * {
    flex: none; }

  &-detail {
    @include flex-width(18rem);
    margin-right: 1.6rem;
    text-align: right;

    > * {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%; }

    &:hover {
      .account-name {
        color: #FFF; } } }

  &-name {
    line-height: 1.9rem;
    max-height: 1.9rem;
    transition: max-height .25s;
    display: block;
    transition: color .25s; }

  &-email,
  &-role {
    font-size: 1.5rem;
    line-height: 1.7rem;
    margin-top: .5rem;
    color: $color-4;
    max-height: 1.7rem; }

  &-email {
    transition: color .25s; }

  &-role {
    transition: max-height .25s; }

  &-logout {
    border: none;
    background: none;
    border-radius: 0;
    font-size: 0;
    padding: 0;

    svg {
      display: block;
      fill: $color-3;
      transition: fill .25s; }

    &:hover {
      svg {
        fill: #FFF; } } } }
