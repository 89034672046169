.root:not(.region-ru) {
  a {
    &:not([class]) {
      color: #1F409D; } }

  .highlight {
    color: #1F409D; }

  .autocomplete:hover {
    background: #1F409D; }

  .button {
    background: #1F409D;
    border: .1rem solid #1F409D;

    &:hover,
    &:active {
      background: #5176E0;
      border-color: #5176E0; }

    &.button--secondary {
      background-color: transparent;
      color: #1F409D;

      svg {
        fill: #1F409D; }

      &:hover,
      &:active {
        color: #5176E0; } } }

  .button-link {
    color: #1F409D; }

  .radio {
    span {
      &::after {
        background: #1F409D; } } }

  .control-circle {
    border: .2rem solid #1F409D;

    &.control-circle--minus {
      &::before {
        background-color: #1F409D; } }

    &.control-circle--plus {
      &::before,
      &::after {
        background-color: #1F409D; } }

    &:hover {
      border-color: #5176E0;
      background-color: #5176E0; } }

  .download-link {
    color: #1F409D;

    &::after {
      background-image: url('/assets/img/chain-xplore.svg'); } }

  .drag-and-drop {
    border: .1rem dashed #1F409D;
    background-color: rgba(#1F409D, .04);

    &:hover {
      background-color: rgba(#1F409D, .08); } }

  //.field
  //  &.field--error
  //    .field-input
  //      border-color: #1F409D
  //      color: #1F409D
  //
  //    .field-label
  //      color: #1F409D

  //&.field--required
  //  &::before
  //    color: #1F409D

  .field {
    &.field--tooltip {
      .tooltip {
        &:hover {
          svg {
            fill: #1F409D; } } } } }

  .form {
    &-group {
      &-title {
        button {
          color: #1F409D; } } }

    &-file {
      .list {
        &-item {
          &-remove {
            &:hover {
              &::before,
              &::after {
                background-color: #1F409D; } } } } }

      //&.form-file--required
      //  &::before
 }      //    color: #1F409D

    //&-error
    //  color: #1F409D

    &-progress {
      &-line {
        > div {
          background-color: #1F409D; } } }

    &-license {
      &-toggler {
        &:hover {
          border-color: #1F409D; } }

      &-title {
        background-image: url('/assets/img/org-title-xplore.svg'); } } }

  .logotype {
    background-color: #1F409D;
    background-image: url('/assets/img/logotype-xplore.svg'); }

  .page-user {
    .header {
      background-color: #1F409D; } }

  .page-login {
    .header {
      background-color: #1F409D; } }

  .list--links {
    .list-link,
    button {
      color: #1F409D;

      svg {
        fill: #1F409D; } } }

  .loader {
    &-progress {
      color: #1F409D; } }

  .modal {
    &-container {
      h4 {
        color: #1F409D; } }

    &-title {
      color: #1F409D; }

    &-close {
      &:hover {
        &::before,
        &::after {
          background-color: #1F409D; } } } }

  .multiselect {
    //&.multiselect--error
    //  .list
    //    border-color: #1F409D
    //
    //&.multiselect--required
    //  &::before
    //    color: #1F409D

    &-close {
      &:hover {
        &::before,
        &::after {
          background-color: #1F409D; } } } }

  .page-user {
    @media (max-width: 767px) {
      .pagetitle {
        h1 {
          color: #1F409D; } } } }

  .list--pagination {
    .list-item {
      .list-link {
        color: #1F409D; }

      &.list-item--active {
        .list-link {
          color: $color-5;
          background-color: #1F409D; } }

      &.list-item--start,
      &.list-item--end {
        .list-link {
          &::before {
            background-image: url('/assets/img/arrow-double-xplore.svg'); } } }

      &.list-item--prev,
      &.list-item--next {
        .list-link {
          &::before {
            background-image: url('/assets/img/arrow-xplore.svg'); } } } } }

  .radio-list {
    span {
      &::after {
        background-color: #1F409D; } } }

  .pirogov-container {
    .pirogov-select {
      &.pirogov-select--error {
        .pirogov__control {
          border-color: #1F409D; }

        .pirogov-select-placeholder {
          color: #1F409D; } }

      //&.pirogov-select--required
      //  &::before
 } }      //    color: #1F409D

  //.select
  //  &.select--required
  //    &::before
  //      color: #1F409D
  //
  //  &.select--error
  //    select
  //      border-color: #1F409D
  //
  //    .select-label
  //      color: #1F409D

  .sidebar {
    .list-sidebar {
      .list-item {
        &.list-item--active {
          .list-link {
            &.list-link--request {
              color: #fff;

              svg {
                fill: #fff; } } } } }

      .list-item-count {
        background: #1F409D; }

      .list-link {
        &:hover {
          color: #1F409D; }

        &.list-link--icon {
          &:hover {
            svg {
              fill: #1F409D; } } }

        &.list-link--request {
          background: #1F409D;

          &:hover {
            background: #5176E0; } } } } }

  .page-user {
    @media (max-width: 1023px) {
      .sidebar {
        .list-sidebar {
          .list-item {
            &.list-item--active {
              .list-link {
                color: lighten(#5176E0, 6); } } }

          .list-link {
            &:hover {
              color: lighten(#5176E0, 12); } } } } } }

  .sorting {
    .list {
      &-item {
        span {
          color: #1F409D;
          border-bottom: .1rem solid #1F409D; }

        &--up {
          &::before {
            border-bottom-color: #1F409D; } }

        &--down {
          &::before {
            border-top-color: #1F409D; } } } } }

  .support-item {
    &-holder {
      &:hover {
        border-color: #1F409D; } } }

  .table {
    &-chain {
      a {
        svg {
          fill: #1F409D; } } }

    &-delete {
      button {
        &:hover {
          svg {
            fill: #1F409D; } } } } }

  .task-status {
    &.task-status--new {
      color: #1F409D; }

    &.task-status--rejected {
      color: #5176E0; } }

  .organization-menu {
    .list {
      &-item {
        &-icon {
          background-color: #1F409D; }

        &-title {
          color: #1F409D; } }

      &-link {
        &:hover {
          .list-item-title {
            background-color: #1F409D; } } } } }

  .form-license-pack {
    &__heading {
      &:hover {
        h4.form-license-pack__title {
          color: #5176E0;

          &::before {
            border-color: #5176E0; } } } }

    h4.form-license-pack__title {
      &::before {
        border-left: .2rem solid #1F409D;
        border-bottom: .2rem solid #1F409D; } }

    &__group {
      &-delete {
        color: #1F409D;

        &:hover {
          color: #5176E0; } } }

    &__create {
      span {
        color: #1F409D; }

      &:hover {
        &::before {
          background-color: #1F409D; } } } }

  .error-page {
    h1 {
      color: #1F409D; } }

  .login {
    &-title {
      color: #1F409D; } }

  .support-comments {
    &-name {
      color: #1F409D; }

    &-control {
      svg {
        fill: #1F409D; } }

    &-userpic {
      &-image {
        background-image: url('/assets/img/userpic-xplore.svg'); } } }

  .faq-title {
    @media (max-width: 767px) {
      color: #1F409D; } }

  .faq-menu {
    ol {
      color: #1F409D;

      a {
        &:hover {
          color: #1F409D; } } } }

  .user-license {
    &-intro {
      h4 {
        color: #1F409D; }

      &.user-license-intro--activated {
        &:hover {
          border-color: #1F409D; } } }

    &-desc {
      &-download {
        &:hover {
          color: #1F409D;
          border-color: #1F409D; } } }

    @media (max-width: 767px) {
      h1 {
        color: #1F409D; } } }

  .license-card {
    &__header {
      background-color: #1F409D; } }

  .info-item {
    &-check {
      background-image: url('/assets/img/check-icon-xplore.svg'); } }

  .checkbox {
    span {
      &::after {
        background-image: url('/assets/img/check-icon-xplore.svg'); } } }

  .license-request {
    &-title {
      background-image: url('/assets/img/org-title-xplore.svg'); } }

  .user-help-title {
    color: #1F409D; } }
