/* FONT
 * -------------------------------------------------- */

/*
 * 100 = thin
 * 200 = extra-light
 * 300 = light
 * 400 = normal, book
 * 500 = medium
 * 600 = semi-bold
 * 700 = bold
 * 800 = heavy
 * 900 = black
 */

// +font-face('Rouble', 'ALSRubl', 400)

@include font-face('PF Din Text', 'PFDinTextCondPro-Regular', 400);
@include font-face('PF Din Text', 'PFDinTextCondPro-Italic', 400, italic);
@include font-face('PF Din Text', 'PFDinTextCondPro-Medium', 700);
@include font-face('PF Din Text', 'PFDinTextCondPro-MediumItalic', 700, italic);
