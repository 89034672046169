.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(#fff, .7);
	z-index: 1000;
	text-align: center;
	display: none;
	opacity: 0;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	backface-visibility: hidden;
	outline: none;
	transition: opacity .3s ease;
	padding: 2rem;

	&::before {
		content: '';
		display: inline-block;
		height: 100%;
		vertical-align: middle;
		margin-left: -.28em; }

	&-container {
		width: 100%;
		max-width: 42.4rem;
		margin: 0 auto;
		padding: 3rem 4rem 4rem 4rem;
		background: #fff;
		position: relative;
		display: inline-block;
		vertical-align: middle;
		text-align: left;
		transform: translateY(-3rem);
		transition: .25s ease;
		border: .1rem solid $color-6;

		h4 {
			color: $colorMain;
			text-transform: uppercase;
			font-size: 2.5rem;
			text-align: center; }

		p {
			text-align: center;
			overflow: hidden;
			text-overflow: ellipsis; }

		.form {
			&-row {
				margin-left: -2rem; }

			&-col {
				padding-left: 2rem; }

			&-controls {
				margin-left: -2rem;

				.button {
					margin-left: 2rem; } }

			&.form--update {
				.form-row {
					margin-left: -4rem; }

				.form-col {
					padding-left: 4rem; }

				.form-controls {
					.button {
						width: 16.6%; } } } }

		.button-row {
			text-align: center;
			margin-left: -1.8rem;
			margin-top: 2rem;

			.button {
				margin-left: 1.8rem;

				&.button--secondary {
					min-width: 15rem; }

				&.button--primary {
					min-width: 16rem; }

				&.button-ok {
					min-width: auto; } }

			&.button-row--right {
				text-align: right; }

			&.button-row--description {
				margin-left: -1.5rem;

				.button {
					margin-left: 1.5rem;
					margin-top: 1.5rem;
					padding-left: 2.5rem;
					padding-right: 2.5rem;

					&.button--secondary {
						min-width: 13.8rem; }

					&.button--primary {
						min-width: 16.2rem; } } } } }

	&-title {
		line-height: 3.2rem;
		text-transform: uppercase;
		color: $colorMain;
		text-align: center;
		margin-bottom: 2rem;

		&.modal-title--lawercase {
			text-transform: none;
			margin-bottom: 3rem; } }

	&-footnote {
		font-size: 1.4rem;
		line-height: 1.4;
		text-align: center;
		max-width: 24rem;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 2rem;
		margin-top: -1.3rem; }

	&-description {
		&-row {
			@include flex();

			+ .modal-description-row {
				margin-top: 2rem; } }

		&-label {
			@include flex-width(8.5rem);
			text-align: right;
			color: $color-4; }

		&-value {
			flex: 1;
			padding-left: 1.6rem; }

		&-scroll {
			max-height: 11.4rem;
			overflow-y: auto; } }

	&-close {
		position: absolute;
		top: 0;
		right: 0;
		width: 4rem;
		height: 4rem;
		background: transparent;
		text-decoration: none;
		border: 0;
		padding: 0;
		cursor: pointer;

		&::before,
		&::after {
			@include content();
			width: 1.4rem;
			height: .2rem;
			top: 50%;
			left: 50%;
			margin: -.1rem 0 0 -.8rem;
			background-color: $color-4;
			transition: background-color .25s; }

		&::before {
			transform: rotate(-45deg); }

		&::after {
			transform: rotate(45deg); }

		&:hover {
			&::before,
			&::after {
				background-color: $colorMain; } } }

	&-log {
		overflow-y: auto;
		height: 22rem;
		padding: .9rem 1.9rem;
		border: .1rem solid $color-4;

		&-item + &-item {
			margin-top: 2rem; }

		&-error {
			line-height: 1.2; }

		&-info {
			line-height: 1.2;
			color: $color-4; } }

	&.modal--opened {
		display: block; }

	&.modal--visible {
		opacity: 1;

		.modal-container {
			transform: translateY(0); } }

	&.modal--middle {
		.modal-container {
			max-width: 53rem; } }

	&.modal--large {
		.modal-container {
			max-width: 73rem; }

		&-form {
			.modal-container {
				max-width: 60rem; } } }

	&.modal--description {
		.modal-container {
			max-width: 55rem; } }

	&.modal--update,
	&.modal--notification {
		.modal-container {
			max-width: 96rem; } }

	&.modal--notification {
		.modal-log {
			padding: .5rem 1.2rem;
			height: 19.8rem;

			&-item {
				line-height: 1;

				+ .modal-log-item {
					margin-top: .8rem; } } } }

	&.modal--error {
		.modal-container {
			.modal-error-time {
				text-align: center;
				margin-bottom: 2rem; }

			.modal-error-description {
				text-align: left;

				p {
					text-align: inherit; } }

			h4 {
				margin-bottom: 0; } } }

	&.modal-animation-enter {
		display: block;
		opacity: 0;

		.modal-container {
			opacity: 0;
			transform: translateY(-3rem); } }

	&.modal-animation-enter-active,
	&.modal-animation-enter-done {
		display: block;
		opacity: 1;
		transition: opacity .25s ease;

		.modal-container {
			opacity: 1;
			transform: translateY(0);
			transition: opacity .25s, transform .25s ease; } }

	&.modal-animation-exit {
		opacity: 1;

		.modal-container {
			opacity: 1;
			transform: translateY(0); } }

	&.modal-animation-exit-active {
		display: none;
		opacity: 0;
		transition: opacity .25s;

		.modal-container {
			opacity: 0;
			transform: translateY(3rem);
			transition: opacity .25s, transform .25s ease; } } }

@supports ((-webkit-backdrop-filter: blur(1.6rem)) or (backdrop-filter: blur(1.6rem))) {
	.modal {
		background: none;
		backdrop-filter: blur(1.6rem); } }

.page-user {
	@media (max-width: 599px) {
		~ .modal {
			padding: 0;

			&::before {
				display: none; }

			.modal-container {
				padding-left: 2rem;
				padding-right: 2rem;
				min-height: 100vh;
				display: block;
				transform: none;
				border: none; } } } }
