.support-list {}

.support-item {
  &-holder {
    @include flex();
    border: .1rem solid $color-4;
    background-color: $color-5;
    padding: 1.5rem;
    cursor: pointer;
    position: relative;
    transition: border-color .25s;

    &:hover {
      z-index: 1;
      border-color: $colorMain; } }

  &-number {
    font-weight: 700;
    @include flex-width(5rem);
    word-break: break-all; }

  &-content {
    flex: 1;
    padding-right: 3rem;

    h4 {
      font-size: 1.7rem;
      line-height: 1.4;
      font-weight: 700;
      margin-bottom: 0; } }

  &-theme {
    font-size: 1.5rem;
    margin-top: 1rem; }

  &-date {
    font-size: 1.5rem;
    margin-top: 1rem; }

  &-data {
    font-size: 0;
    margin-top: 1rem;

    span {
      display: inline-block;
      vertical-align: top;
      font-size: 1.5rem;

      + span {
        margin-left: 1.5rem; } } }

  &-status {
    @include flex();
    align-items: center;
    justify-content: flex-start;
    @include flex-width(13rem);
    padding-left: 2rem;
    border-left: .1rem solid $color-4; }

  + .support-item {
    margin-top: -.1rem; }

  &.support-item--update {
    .support-item-holder {
      background-color: lighten(#ffae00, 45%); } } }

@media (max-width: 599px) {
  .page-user {
    .support-item {
      &-holder {
        display: block; }

      &-number {
        max-width: none;
        margin-bottom: 1rem;
        font-size: 1.5rem; }

      &-status {
        display: block;
        max-width: none;
        padding-left: 0;
        border: none;
        margin-top: 2rem; } } } }
