/* FILTERS
 * -------------------------------------------------- */

.filters {
	margin: -1rem 0 3.6rem -1rem;
	@include flex();

	> * {
		flex: 1; }

	&.filters--lines {
		display: block;
		margin-top: 0;
		margin-left: 0; }

	&-line {
		@include flex();
		@include flex-width(100%);
		margin: -1rem 0 0 -1rem;

		+ .filters-line {
			margin-top: 0; } }

	&-item {
		margin: 1rem 0 0 1rem;

		&.filters-item--search {
			@include flex-width(30rem); }

		&.filters-item--search-middle {
			@include flex-width(25rem); }

		&.filters-item--view {
			@include flex-width(14rem); }

		&.filters-item--select {
			@include flex-width(21rem); }

		&.filters-item--select-small {
			@include flex-width(16rem); }

		&.filters-item--select-middle {
			@include flex-width(18rem); }

		&.filters-item--buttons {
			white-space: nowrap;

			.button-row {
				margin: -1rem 0 0 -1rem;
				text-align: right;

				.button {
					margin-top: 1rem;
					margin-left: 1rem;
					width: 14.5rem;
					padding-left: 1rem;
					padding-right: 1rem;

					&.button--large {
						width: 20.5rem; } } }

			&.filters-item--buttons-fullrow {
				flex: none;
				width: calc(100% - 1rem);
				margin-bottom: 1rem; } }

		&.filters-item--small {
			&.filters-item--search {
				@include flex-width(25rem); } }

		&.filters-item--calendar {
			@include flex();
			@include flex-width(100%);

			.field {
				@include flex-width(18.4rem); } }

		&.filters-item--checkbox {
			padding-left: 1rem;
			padding-top: .7rem;
			flex: none;

			.checkbox {
				vertical-align: top; } }

		&.filters-item--calendar-once {
			@include flex-width(18.4rem); }

		&.filters-item--calendar-inline {
			@include flex-width(40rem);
			@include flex();

			.field {
				@include flex-width(17.8rem); } }

		+ .filters-item--calendar-inline {
			@include flex-width(39.2rem); }

		&.filters-item--search-by {
			@include flex();
			align-items: center; }

		.filters-item-delimiter {
			@include flex-width(3.6rem);
			text-align: center;
			line-height: 3.6rem;
			color: $color-4; }

		.filters-item-label {
			margin-right: 1rem; } }

	&.filters--license-short {
		.filters-item {
			&.filters-item--view {
				@include flex-width(18.4rem); } } }

	&.filters--support {
		margin-bottom: 2rem;

		.filters-item.filters-item--view {
			margin-left: auto; } }

	@media (max-width: 1105px) {
		&-item {
			&.filters-item--buttons {
				.button-row {
					.button {
						width: 12.7rem; } } } } } }

@media (max-width: 767px) {
	.page-user {
		.filters {
			&.filters--support {
				.filters-item.filters-item--search {
					flex: none;
					max-width: none;
					width: calc(100% - 1rem); }

				.filters-item.filters-item--view {
					margin-left: 1rem; } } } } }

@media (max-width: 599px) {
	.page-user {
		.filters {
			&.filters--support {
				.filters-item.filters-item--select {
					max-width: none;
					flex: 1; } } } } }

@media (max-width: 374px) {
	.page-user {
		.filters {
			&.filters--support {
				.filters-item.filters-item--view {
					@include flex-width(13rem); } } } } }
