@import '../config/base';

.profile-settings-info {
  border-bottom: .1rem dashed $color-4;
  padding-bottom: 1.2rem;
  margin-bottom: 1.7rem;

  p {
    margin-bottom: .4rem;

    &:last-child {
      margin-bottom: 0; } }

  @media (max-width: 767px) {
    padding-bottom: 1.5rem;
    margin-bottom: 3rem; } }

.pagetitle + .profile-settings-info {
  margin-top: -1.6rem;

  @media (max-width: 767px) {
    margin-top: 0; } }

.profile-account-edit {
  padding-top: 1.2rem;
  margin-top: 1.7rem;
  border-top: .1rem dashed $color-4;

  p {
    margin-bottom: 2rem; } }
