/* HEADER
 * -------------------------------------------------- */

.header {
	height: 10rem;
	max-height: 10rem;
	width: 100%;
	left: 0;
	top: 0;
	background: $color-2;
	color: $color-3;
	box-shadow: 0 .5rem 1.9rem rgba(#000, .3);
	transition: max-height .25s;
	z-index: 9;
	position: fixed;
	padding-left: calc(100vw - 100%);

	.container {
		display: flex;
		height: 100%;
		align-items: center; }

	&-menu {
		display: none;
		position: absolute;
		top: 50%;
		left: 2rem;
		margin-top: -1rem;
		width: 2.6rem;
		height: 2rem;
		border: none;
		border-radius: 0;
		padding: 0;
		background: none;
		font-size: 0;
		line-height: 1;

		&::before,
		&::after {
			@include content(); }

		span {
			display: block;
			position: absolute; }

		&::before,
		&::after,
		span {
			width: 100%;
			height: .2rem;
			background-color: #fff; }

		&::before {
			top: 0; }

		span {
			top: 50%;
			margin-top: -.1rem; }

		&::after {
			bottom: 0; } }

	&-logotype {
		height: 100%;
		flex: none;
		@include flex-width(26rem); }

	&-title {
		padding: 0 2rem;
		width: calc(100% - 47.6rem);
		flex: none;

		h3 {
			font-size: 2rem;
			margin-bottom: 0;
			flex: none;
			overflow: hidden;
			max-height: 7.2rem;
			max-width: 100%; } }

	@media (min-width: 1024px) {
		&.header--scroll {
			max-height: 5rem;

			.logotype {
				background-size: 12.34rem 4.2rem; }

			.header-title {
				height: 2.4rem;

				h3 {
					white-space: nowrap;
					text-overflow: ellipsis; } }

			.account {
				&-name,
				&-role {
					max-height: 0; }

				&-email {
					color: $color-3; } }

			+ .main {
				.content {
					.sidebar {
						padding-top: 5rem; } } } } } }

.logotype {
	display: block;
	width: 100%;
	height: 100%;
	background: $colorMain url('/assets/img/logotype.svg') no-repeat 50% 50% / 17.1262rem 5.829rem;
	font-size: 0;
	transition: background .25s; }

.page-admin-org,
.page-admin-wz {
	@media (max-width: 1023px) {
		.header {
			position: absolute;
			min-width: 102.4rem; } } }

.page-user {
	.header {
		background: $colorMain url('/assets/img/user-header-bg.svg') repeat-x 0 50% / auto 10rem;
		overflow: hidden;

		.container {
			position: relative;

			&::before {
				@include content();
				top: 0;
				bottom: 0;
				right: 0;
				left: 27rem;
				background-color: $color-2;
				box-shadow: 0 .5rem 1.9rem rgba(#000, .3); }

			> * {
				position: relative; } } }

	.logotype {
		background-color: transparent; }

	@media (max-width: 1023px) {
		.header {
			max-height: 7rem;

			.container {
				max-width: 17.6rem;
				padding: 0;
				display: block; }

			&-menu {
				display: block; }

			&-logotype {
				max-width: none;

				.logotype {
					background-color: $color-2;
					background-size: 14.2rem auto;
					box-shadow: none; } }

			&-title {
				display: none; }

			.account {
				display: none; } } } }

.page-login {
	.header {
		background: $colorMain url('/assets/img/user-header-bg.svg') repeat-x 0 50% / auto 10rem;
		overflow: hidden;
		position: relative;

		.container {
			display: block; }

		&-logotype {
			max-width: none;

			.logotype {
				background-color: $color-2;
				box-shadow: 0 .5rem 1.9rem rgba(#000, .3); } } }

	@media (max-width: 1023px) {
		.header {
			height: 7rem;

			.container {
				max-width: 17.6rem;
				padding: 0; }

			.logotype {
				background-size: 14.2rem auto;
				box-shadow: none; } } }

	// @media (max-width: 599px)
	// 	.header
	// 		height: 5rem

	// 		.container
	// 			max-width: 15rem

	// 		.logotype
 }	// 			background-size: 11rem auto
