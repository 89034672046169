.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#FFF, .5);
  z-index: 9999;

  &::after {
    @include content();
    background: url('/assets/img/loader.png') no-repeat 50% 50% / 100% 100%;
    top: 50%;
    left: 50%;
    width: 16rem;
    height: 17.2rem;
    margin: -8.6rem 0 0 -8rem; }

  &-progress {
    font-size: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -12.3rem 0 0 -12.3rem;
    width: 24.6rem;
    height: 24.6rem;
    border-radius: 50%;
    box-shadow: inset 0 0 0 .7rem;
    transform: translateZ(0);
    color: $colorMain;
    background: #FFF;

    &::before,
    &::after {
      @include content();
      border-radius: 50%; }

    &:before {
      width: 12.5rem;
      height: 24.8rem;
      background: #fff;
      border-radius: 24.8rem 0 0 24.8rem;
      top: -0.1rem;
      left: -0.1rem;
      transform-origin: 12.4rem 12.4rem;
      animation: loader 1s infinite ease .75s; }

    &::after {
      width: 12.5rem;
      height: 24.8rem;
      background:  #fff;
      border-radius: 0 24.8rem 24.8rem 0;
      top: -0.1rem;
      left: 12.2rem;
      transform-origin: 0.1rem 12.4rem;
      animation: loader 1s infinite ease; } } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
