.autocomplete {
	position: relative;

	.field {
		&-input {
			padding-right: 4rem; } }

	&-clear {
		display: block;
		position: absolute;
		top: 50%;
		right: 1rem;
		transform: translateY(-50%);
		width: 1.8rem;
		height: 1.8rem;
		border-radius: 50%;
		border: none;
		padding: 0;
		font-size: 0;
		margin: 0;
		background: $color-6;
		transition: background-color .25s;

		&::before,
		&::after {
			@include content();
			width: 1rem;
			height: .2rem;
			top: 50%;
			left: 50%;
			margin: -.1rem 0 0 -.5rem;
			background-color: #FFFFFF;
			backface-visibility: hidden; }

		&::before {
			transform: rotate(-45deg); }

		&::after {
			transform: rotate(45deg); }

		&:hover {
			background: $colorMain; } }

	&-no-results {
		padding: .5rem 1.2rem; }

	&-dropdown {
		position: absolute;
		top: 3.6rem;
		left: 0px;
		width: 100%;
		z-index: 10;
		background-color: #FFFFFF;
		padding: 0.5rem 0;
		border: .1rem solid rgb(110, 97, 102);
		margin-top: -.1rem;
		display: none;

		.list {
			&-item {
				padding: .5rem 1.2rem;
				cursor: pointer;
				transition: background-color .25s;

				&:hover {
					background-color: $color-3; } } } } }
