.task-status {
	display: inline-block;
	line-height: 2rem;
	vertical-align: top;
	height: 2rem;
	font-size: 1.5rem;
	padding-left: 3.2rem;
	background-repeat: no-repeat;
	background-position: 0 0;
	background-size: 2rem 2rem;

	&.task-status--new {
		background-image: url('/assets/img/status/new.svg');
		color: $colorMain; }

	&.task-status--in_processing {
		background-image: url('/assets/img/status/in-work.svg');
		background-size: 2rem 1.9717rem;
		color: $color-9; }

	&.task-status--temp_solution {
		background-image: url('/assets/img/status/temporarily.svg');
		color: $color-4; }

	&.task-status--processed,
	&.task-status--solved {
		background-image: url('/assets/img/status/done.svg');
		color: $color-8; }

	&.task-status--rejected {
		background-image: url('/assets/img/status/rejected.svg');
		color: $colorMain2; } }
