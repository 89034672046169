/* PAGETITLE
 * -------------------------------------------------- */

.pagetitle {
  border-bottom: .1rem dashed $color-4;
  padding-bottom: 1.5rem;
  margin-bottom: 2.8rem;

  h1 {
    font-weight: 700;
    font-size: 2.2rem;
    margin-bottom: 0; }

  &.pagetitle--crumbs {
    font-size: 0;

    > *:not(.list) {
      display: inline-block;
      vertical-align: top; } }

		.list--links {
			font-size: 1.7rem;
			margin-top: 1rem; }

  &-parent {
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 1.2;
    max-width: 100%;
    position: relative;
    padding-right: 3.6rem;

    &::after {
      @include content();
      top: 50%;
      right: 1rem;
      width: 1rem;
      height: 1rem;
      margin-top: -.4rem;
      border: .5rem solid transparent;
      border-left-color: $color-4; }

    a {
      display: block;
      max-width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; } }

  &-secondary {
    font-size: 1.7rem;
    line-height: 2.4rem;
    font-weight: 700; }

  &-description {
    font-size: 1.7rem;
    width: 100%;
    margin-top: .2rem;
    margin-bottom: .3rem; } }

.page-user {
  @media (max-width: 767px) {
    .pagetitle {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 3rem;

      h1 {
        font-size: 2rem;
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
        color: $colorMain;
        letter-spacing: .2rem; } } } }
