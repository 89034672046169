.field--calendar {
  .react-datepicker {
    font-family: inherit;
    font-size: 1.5rem;
    border-radius: .5rem; }

  .react-datepicker--time-only .react-datepicker__time, .react-datepicker--time-only .react-datepicker__time-box {
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem; }

  .react-datepicker__header {
    border-top-left-radius: .5rem; }

  .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: .5rem; }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-size: 1.7rem; }

  .react-datepicker__navigation {
    line-height: 2.5rem;
    border-width: .7rem; }

  .react-datepicker__year {
    margin: .7rem; }

  .react-datepicker__year .react-datepicker__year-text {
    width: 7rem; }

  .react-datepicker__month {
    margin: .7rem; }

  .react-datepicker__month .react-datepicker__month-text,
  .react-datepicker__month .react-datepicker__quarter-text {
    width: 7rem; }

  .react-datepicker__time-container--with-today-button {
    border-radius: .5rem; }

  .react-datepicker__time-container .react-datepicker__time {
    border-bottom-right-radius: .5rem; }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    border-bottom-right-radius: 0.5rem; }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    height: calc(195px + (2.5rem / 2)); }

  .react-datepicker__week-number {
    width: 2.5rem;
    line-height: 2.5rem;
    margin: .24rem; }

  .react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
    border-radius: .5rem; }


  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 2.5rem;
    line-height: 2.5rem;
    margin: .24rem; }

  .react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range,
  .react-datepicker__quarter--selected,
  .react-datepicker__quarter--in-selecting-range,
  .react-datepicker__quarter--in-range {
    border-radius: .5rem; }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    border-radius: .5rem; }

  .react-datepicker__day--highlighted,
  .react-datepicker__month-text--highlighted,
  .react-datepicker__quarter-text--highlighted,
  .react-datepicker__year-text--highlighted {
    border-radius: 0.5rem; }

  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: .5rem; }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    border-radius: .5rem; }

  .react-datepicker__year-read-view,
  .react-datepicker__month-read-view,
  .react-datepicker__month-year-read-view {
    border-radius: .5rem; }

  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    border-width: .7rem; }

  .react-datepicker__year-dropdown,
  .react-datepicker__month-dropdown,
  .react-datepicker__month-year-dropdown {
    border-radius: .5rem; }

  .react-datepicker__year-option:first-of-type,
  .react-datepicker__month-option:first-of-type,
  .react-datepicker__month-year-option:first-of-type {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem; }

  .react-datepicker__year-option:last-of-type,
  .react-datepicker__month-option:last-of-type,
  .react-datepicker__month-year-option:last-of-type {
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem; }

  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 5rem;
    line-height: 5rem; }

  @media (max-width: 400px), (max-height: 550px) {
    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .react-datepicker__day,
    .react-datepicker__portal .react-datepicker__time-name {
      width: 3rem;
      line-height: 3rem; } }


  .react-datepicker__portal .react-datepicker__current-month,
  .react-datepicker__portal .react-datepicker-time__header {
    font-size: 2.1rem; }

  .react-datepicker__portal .react-datepicker__navigation {
    border-width: 1.2rem; } }
