/* COMMON
 * -------------------------------------------------- */

html,
body {
	height: 100%; }

html {
	box-sizing: border-box;
	font-size: $baseSize;
	text-size-adjust: 100%;
 }	//scroll-behavior: smooth

*,
*::before,
*::after {
	box-sizing: inherit; }

body {
	min-width: 320px;
	line-height: 1.4;
	font-size: 1.7rem;
	margin: 0;
	direction: ltr;
	text-align: left;
	font-family: $textFontFamily;
	color: $baseColor;
	background: #ffffff;
	font-smooth: always;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeSpeed; }

/* CONTENT
 * -------------------------------------------------- */

a {
	color: inherit;
	text-decoration: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	outline: none;
	transition: color .25s, border-color .25s, background-color .25s, opacity .25s;
	text-decoration-skip-ink: auto;

	svg,
	use {
		pointer-events: none; }

	&:not([class]) {
		color: $colorMain;

		&:hover {
			color: $color-7; } } }

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	margin: 0 0 $headMargin 0;
	font-family: $headFontFamily;
	font-weight: normal;
	color: $headColor;
	line-height: 1.2;
	hyphens: none;

	a {
		text-decoration: none;
		color: inherit; } }

h1,
.h1 {
	font-size: $h1FontSize; }

h2,
.h2 {
	font-size: $h2FontSize; }

h3,
.h3 {
	font-size: $h3FontSize; }

h4,
.h4 {
	font-size: $h4FontSize; }

h5,
.h5 {
	font-size: $h5FontSize; }

h6,
.h6 {
	font-size: $h6FontSize; }

p,
ul,
ol,
dl,
table {
	& + h1,
	& + h2,
	& + h3,
	& + h4,
	& + h5,
	& + h6,
	& + .h1,
	& + .h2,
	& + .h3,
	& + .h4,
	& + .h5,
	& + .h6 {
		margin-top: $textHeadMargin; } }

p {
	margin: 0 0 $textMargin 0; }

ul,
ol {
	padding: 0;
	margin: 0 0 $textMargin 2.5rem; }

ul,
ol {
	ul,
	ol {
		margin-bottom: 0; } }

ul,
ol {
	&:not([class]) {
		li + li {
			margin-top: 0; } } }

dl {
	margin: 0 0 $textMargin 0; }

dt {
	font-weight: bold; }

dd {
	margin-left: 0; }

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative; }

sup {
	top: -50%; }

sub {
	bottom: -25%; }

big {
	font-size: 120%; }

small {
	font-size: 80%; }

tt,
pre,
kbd,
xmp,
code,
samp,
plaintext {
	font-family: monospace, sans-serif; }

pre {
	overflow: auto; }

address {
	font-style: normal; }

i,
em,
dfn,
cite {
	font-style: italic; }

u,
ins,
var {
	text-decoration: underline; }

b,
strong {
	font-weight: bold; }

s,
del,
strike {
	text-decoration: line-through; }

abbr,
acronym {
	cursor: help;
	border-bottom: .1rem dashed #eeeeee; }

mark {
	color: #000000;
	background: #ffff00; }

q,
blockquote {
	quotes: '' '';
	font-style: normal;
	padding: 1rem 2rem;
	margin: 0 0 1rem 0;
	border-left: .5rem solid #eeeeee;

	&::after,
	&::before {
		content: ''; } }

img {
	vertical-align: top;
	-ms-interpolation-mode: bicubic;
	max-width: 100%;
	height: auto;
	border: 0; }

hr {
	display: block;
	height: .1rem;
	border: 0;
	background: #eeeeee;
	margin: 2rem 0;
	padding: 0; }

svg:not(:root) {
	overflow: hidden; }

p,
ul,
ol,
dl,
table,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
q,
blockquote {
	&:last-child {
		margin-bottom: 0; } }

/* TABLE
 * -------------------------------------------------- */

table {
	border-collapse: collapse;
	border-spacing: 0;
	font-variant-numeric: tabular-nums;

	th {
		font-weight: 700; }

	caption {
		text-align: center;
		font-weight: bold;
		padding-bottom: 1rem; }

	&[border] {
		td,
		th {
			border: 0; } }

	&[cellpadding] {
		td,
		th {
			padding: 0; } } }

/* HTML
 * -------------------------------------------------- */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
	display: block; }

audio,
canvas,
progress,
video {
	display: inline-block;
	vertical-align: baseline; }

video {
	max-width: 100%;
	height: auto; }

audio:not([controls]) {
	display: none;
	height: 0; }

[hidden],
template {
	display: none; }

figure {
	margin: 0; }

/* FORM
 * -------------------------------------------------- */

::-ms-clear {
	display: none; }

iframe,
frame,
frameset,
fieldset,
form {
	padding: 0;
	margin: 0;
	border: 0; }

label {
	display: block;
	vertical-align: middle; }

legend {
	padding: 0 .5rem; }

button,
input,
select,
optgroup,
textarea {
	font: inherit;
	color: inherit;
	margin: 0;
	vertical-align: baseline;
	outline: none; }

button {
	overflow: visible; }

button,
select {
	text-transform: none; }

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	opacity: 1;
	height: auto; }

*::-webkit-input-placeholder {
	color: $color-4;
	opacity: 1; }

*:-moz-placeholder {
	color: $color-4;
	opacity: 1; }

*::-moz-placeholder {
	color: $color-4;
	opacity: 1; }

*:-ms-input-placeholder {
	color: $color-4;
	opacity: 1; }

button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
	cursor: pointer;
	appearance: button; }

button[disabled],
input[disabled],
textarea[disabled],
select[disabled] {
	cursor: default;
	filter: grayscale(100%); }

input[type='checkbox'],
input[type='radio'] {
	vertical-align: baseline;
	padding: 0;
	box-sizing: border-box; }

input[type='search'],
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button {
	appearance: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0; }

textarea {
	overflow: auto;
	vertical-align: top;
	resize: none;
	height: auto; }

optgroup {
	font-weight: bold; }
