.group-actions {
	@include flex();
	align-items: center;
	margin-top: 2rem;

	&-label {
		flex: none;
		padding-right: 1rem; }

	&-control {
		font-size: 0;
		flex: 1;

		> * {
			vertical-align: top; }

		.select,
		.pirogov-container {
			display: inline-block;
			margin-right: 1rem;
			width: 15rem;
			font-size: 1.7rem; }

		.button {
			padding-left: 2rem;
			padding-right: 2rem; } } }
