@import '../config/base';

.info-list {
	margin-bottom: 4rem; }

.info-item {
	+ .info-item {
		margin-top: 1.6rem; }

	&-label {
		color: $color-4; }

	&-check {
		padding-left: 2.4rem;
		background: url('/assets/img/check-icon.svg') no-repeat 0 60% / 1.2rem .8949rem; }

	&-content {
		word-break: break-word; }

	&-other-list {
		@include flex();
		margin: -2rem 0 0 -4rem;

		.info-item-content {
			@include flex-width(50%);
			margin-top: 2rem;
			padding-left: 4rem; } } }
