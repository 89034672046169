.footer {
	background-color: #626672;
	color: #fff;
	padding: 2rem 0;

	@media (max-width: 599px) {
		font-size: 1.3rem; }

	.container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center; }

	&-links {
		text-align: center; }

	&-link {
		display: block;

		&:hover {
			text-decoration: underline; } }

	&-link + &-link {
		margin-top: .5rem; }

	&-text {
		margin-top: .5rem;

		a:not([class]) {
			color: inherit;

			&:hover {
				text-decoration: underline; } } } }

