$baseSize: 10px;
$maxViewPortWidth: 108.6rem;

$h1FontSize: 5rem;
$h2FontSize: 3rem;
$h3FontSize: 2.8rem;
$h4FontSize: 2.4rem;
$h5FontSize: 2.2rem;
$h6FontSize: 2rem;

$textMargin: 1rem;
$headMargin: 2rem;
$textHeadMargin: 3rem;

$textFontFamily: 'PF Din Text', sans-serif;
$headFontFamily: 'PF Din Text', sans-serif;

$color-1: #6E6166;
$color-2: #626672;
$color-3: #EBEDF4;
$color-4: #A49FB4;
$color-5: #F9F9F9;
$color-6: #E9E6EF;
$color-7: #9C8CCF;
$color-8: #5A8B46;
$color-9: #6599CE;
$color-10: #CB364D;
$color-11: #474747;
$color-12: #FFFFFF;

$colorMain: #B64758;
$colorMain2: #E53752;

$baseColor: $color-1;
$headColor: inherit;

@mixin content() {
	content: '';
	display: block;
	position: absolute; }

@mixin flex() {
	display: flex;
	flex-wrap: wrap;

	& > * {
		min-width: 0; } }

@mixin flex-width($width) {
	@if $width == 'none' {
		flex: none;
		max-width: none; }
	@else {
		flex: 0 0 $width;
		max-width: $width; } }

@mixin hidden() {
	position: absolute;
	left: 0;
	top: 0;
	width: 0;
	height: 0;
	visibility: hidden;
	overflow: hidden; }

@mixin font-face($family, $filename: $family, $weight: normal, $style: normal) {
	@font-face {
		font-family: "#{$family}";
		font-display: swap;
		src: url('/assets/fonts/#{$filename}.woff2') format('woff2'), url('/assets/fonts/#{$filename}.woff') format('woff');
		font-weight: $weight;
		font-style: $style; } }
