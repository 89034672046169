/* SIDEBAR
 * -------------------------------------------------- */

.sidebar {
	width: 26rem;
	background: $color-5;
	position: fixed;
	top: 0;
	padding-top: 10rem;
	height: 100%;
	transition: padding-top .25s;
	z-index: 1;

	&::before {
		@include content();
		bottom: 0;
		background-repeat: no-repeat;
		background-position: 50% 0;
		overflow: hidden; }

	&-title {
		text-align: center;
		letter-spacing: .25rem;
		font-size: 1.5rem;
		text-transform: uppercase;
		margin-bottom: 1rem; }

	&-wrapper {
		padding: 3.2rem 0;
		overflow-y: auto;
		max-height: 100%;
		position: relative; }

	.list-sidebar {
		.list-item {
			&.list-item--active {
				.list-link {
					background: $color-3;

					&:hover {
						color: $color-1;

						&.list-link--icon {
							svg {
								fill: $color-1; } } }

					&.list-link--request {
						background: $color-3;
						color: $colorMain;

						svg {
							fill: $colorMain; }

						&:hover {
							background: $color-3; } } } }

			&--separate {
				height: .1rem;
				margin: .8rem 2rem;
				background: $color-4; } }

		.list-item-count {
			background: $colorMain;
			color: #fff;
			border-radius: 50%;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			margin-left: 0.6rem;
			width: 2.4rem;
			height: 2.4rem;
			font-weight: 400;
			font-size: 75%;
			text-align: center;
			line-height: 1;
			margin-top: -0.2rem;
			margin-bottom: -0.2rem;
			vertical-align: top; }

		.list-link {
			padding: 1rem 2rem;
			line-height: 2rem;
			transition: background-color .25s;

			&:hover {
				color: $colorMain; }

			&.list-link--icon {
				display: flex;
				align-items: center;

				svg {
					display: block;
					transition: fill .25s;
					fill: $color-1; }

				span {
					display: block;
					padding-left: 1rem; }

				&:hover {
					svg {
						fill: $colorMain; } } }

			&.list-link--request {
				margin-top: .5rem;
				background: $colorMain;
				color: #F0F0F0;
				display: flex;
				align-items: center;

				svg {
					fill: #F0F0F0;
					display: block;
					transition: fill .25s; }

				span {
					display: block;
					padding-left: 1.3rem;

					strong {
						color: inherit; } }

				&:hover {
					background: $colorMain2; } } } }

	&.sidebar--admin-org {
		&::before {
			height: 16.6rem;
			width: 25.2rem;
			background-image: url('/assets/img/admin-org.svg');
			left: .4rem;
			background-size: 100% 100%; } }

	&.sidebar--admin-wz {
		&::before {
			width: 24.6079rem;
			height: 22.9798rem;
			background-image: url('/assets/img/admin-wz.svg');
			left: .7rem;
			background-size: 100% auto; } }

	&.sidebar--user {
		&::before {
			height: 28.6027rem;
			width: 25.0493rem;
			background-image: url('/assets/img/user.svg');
			left: .5rem;
			background-size: 100% 100%; } } }

.page-admin-org,
.page-admin-wz {
	@media (max-width: 1023px) {
		.sidebar {
			position: absolute;
			height: 100vh; } } }

.page-user {
	.sidebar {
		&-heading {
			display: none;
			height: 29rem;
			overflow: hidden;

			&-controls {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-top: 2rem;
				padding: 0 1.5rem;

				> * {
					min-width: 0; } } }

		&-close,
		&-account-logout {
			width: 3rem;
			height: 3rem;
			background: transparent;
			border: none;
			border-radius: 0;
			padding: 0;
			margin: 0;
			font-size: 0; }

		&-close {
			position: relative;

			&::before,
			&::after {
				@include content();
				width: 2rem;
				height: .2rem;
				background-color: #f9f9f9;
				left: .5rem;
				top: 50%;
				margin-top: -.1rem; }

			&::before {
				transform: rotate(-45deg); }

			&::after {
				transform: rotate(45deg); } }

		&-account-logout {
			background: url('/assets/img/exit.svg') no-repeat 50% 50% / 2rem auto; }

		&-account {
			margin-top: 6rem;

			&-image {
				width: 7.2rem;
				height: 7.2rem;
				margin: 0 auto;
				overflow: hidden;

				img {
					display: block;
					width: 100%; } }

			&-name {
				font-size: 2.2rem;
				line-height: 2.4rem;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				text-align: center;
				color: $color-6;
				margin-top: 2rem;
				font-weight: 700; }

			&-email,
			&-role {
				color: #f0f0f0;
				margin-top: .3rem;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				text-align: center;
				font-size: 1.5rem;
				line-height: 2rem; } } }

	@media (max-width: 1023px) {
		.sidebar {
			background: $color-2;
			left: 0;
			width: 36rem;
			max-width: 100%;
			transform: translateX(-100%);
			padding-top: 0;
			z-index: 10;
			transition: transform .5s ease;

			&.sidebar--active {
				transform: translateX(0); }

			&::before {
				display: none; }

			&-wrapper {
				height: 100%;
				padding: 0; }

			&-heading {
				display: block; }

			&-title {
				display: none; }

			.list-sidebar {
				background-color: rgba(darken($color-11, 5%), .75);
				height: calc(100% - 29rem);
				padding: .6rem 2rem 2rem 2rem;

				.list-item {
					&.list-item--active {
						.list-link {
							background: transparent;
							color: lighten($colorMain2, 6);

							&.list-link--icon {
								svg {
									fill: currentColor; } } } }

					+ .list-item {
						border-top: .1rem solid $color-3; } }

				.list-link {
					padding: 1.8rem 0;
					line-height: 2rem;
					transition: background-color .25s;
					color: #f9f9f9;

					&.list-link--icon {
						svg {
							fill: #f9f9f9; } }

					&:hover {
						color: lighten($colorMain2, 12);

						&.list-link--icon {
							svg {
								fill: currentColor; } } } } }

			@supports ((-webkit-backdrop-filter: blur(1.6rem)) or (backdrop-filter: blur(1.6rem))) {
				background: rgba(#000, .5);
				backdrop-filter: blur(1.2rem); } } } }
