.multiselect {
	position: relative;

	&-label {
		@include flex();
		align-items: center;
		padding-bottom: .5rem;

		span {
			flex: 1;
			color: $color-4;
			padding-right: 3rem; } }

	&-label-small {
		position: absolute;
		padding: 0 .6rem;
		left: .7rem;
		top: .8rem;
		max-width: calc(100% - 1.4rem);
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		color: $color-4;
		font-size: 1.6rem;
		height: 2rem;
		line-height: 2rem;
		background-color: #fff;
		transform: translate3d(.6rem, -1.75rem, 0) scale(0.875);
		transition: transform .15s linear;
		backface-visibility: hidden;
		will-change: transform;
		cursor: text;
		transform-origin: center left; }

	&-search {
		margin-bottom: 1rem; }

	.list {
		border: .1rem solid $color-4;
		height: 20.2rem;
		overflow-y: auto;
		padding: .8rem 0;

		&-item {
			label {
				display: block;
				position: relative;
				overflow: hidden; }

			input {
				@include hidden();

				&:checked + span {
					background-color: $color-3; }

				&:disabled + span {
					opacity: .5;
					cursor: default; } }

			span {
				display: block;
				line-height: 2rem;
				padding: .7rem 1.7rem;
				cursor: pointer;
				transition: background-color .25s;

				&:hover {
					background-color: $color-3; } } }

		+ .list-item {
			margin-top: .5rem; } }

	&.multiselect--short {
		.list {
			height: 11.2rem; } }

	&-col {
		display: flex;
		align-items: flex-end; }

	&-controls {
		padding-top: 2.8rem;

		.button {
			width: 100%;
			padding-left: 2rem;
			padding-right: 2rem;

			+ .button {
				margin-top: 2rem; } }

		&-item {
			.button {
				+ .button {
					margin-top: 1rem; } }

			+ .multiselect-controls-item {
				margin-top: 3rem; } } }

	&.multiselect--error {
		.list {
			border-color: $colorMain; } }

	&.multiselect--disabled {
		opacity: 0.5;
		filter: grayscale(1); }

	&.multiselect--required {
		position: relative;

		&::before {
			position: absolute;
			display: block;
			content: '*';
			color: $colorMain;
			font-size: 2.4rem;
			font-weight: 700;
			line-height: 1;
			right: -1.2rem;
			top: -.3rem; } } }

