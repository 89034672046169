.pirogov-container {
	.pirogov-select {
		position: relative;
		background-color: #fff;

		&.pirogov-select--notempty {
			.pirogov-select-placeholder {
				transform: translate3d(.6rem, -1.85rem, 0) scale(0.875);
				z-index: 1;
				transition: transform .15s linear; } }

		&.pirogov-select--error {
			.pirogov__control {
				border-color: $colorMain; }

			.pirogov-select-placeholder {
				color: $colorMain; } }

		&.pirogov-select--is-disabled {
			opacity: .5; }

		&.pirogov-select--required {
			position: relative;

			&::before {
				position: absolute;
				display: block;
				content: '*';
				color: $colorMain;
				font-size: 2.4rem;
				font-weight: 700;
				line-height: 1;
				right: -1.2rem;
				top: -.3rem; } } }

	.pirogov__control {
		min-height: 3.6rem;
		box-shadow: none;
		z-index: 1;
		align-items: stretch;
		background-color: transparent;
		border-radius: 0;
		border-color: $color-4;

		&.pirogov__control--is-focused {
			border-color: $color-1;

			&:hover {
				border-color: $color-1; }

			+ .pirogov-select-placeholder {
				transform: translate3d(.6rem, -1.85rem, 0) scale(0.875);
				z-index: 1;
				transition: transform .15s linear; } }

		&.pirogov__control--menu-is-open {
			.pirogov__indicators {
				&::after {
					transform: rotate(225deg);
					margin-top: -.25rem; } } } }

	.pirogov__placeholder {
		visibility: hidden; }

	.pirogov__multi-value {
		margin: .4rem;
		background-color: $color-3;
		border-radius: 2rem;

		&__label {
			padding-left: 1rem;
			font-size: 1.5rem;
			padding-top: .1rem;
			padding-bottom: .1rem; }

		&__remove {
			padding: 0;
			width: 2rem;
			border-radius: 50%;
			cursor: pointer;
			position: relative;

			&::before,
			&::after {
				@include content();
				width: 1rem;
				height: .1rem;
				left: 50%;
				top: 50%;
				background-color: $color-1; }

			&::before {
				transform: translate(-50%, -50%) rotate(-45deg); }

			&::after {
				transform: translate(-50%, -50%) rotate(45deg); }

			&:hover {
				&::before,
				&::after {
					background-color: #DE350B; } }

			svg {
				display: none; } } }

	.pirogov-select-placeholder {
		position: absolute;
		padding: 0 .6rem;
		left: .7rem;
		top: .8rem;
		max-width: calc(100% - 3.2rem);
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		color: $color-4;
		font-size: 1.6rem;
		height: 2rem;
		line-height: 2rem;
		background-color: #fff;
		transition: transform .15s linear, z-index .25s 0s;
		backface-visibility: hidden;
		will-change: transform;
		cursor: text;
		transform-origin: center left;
		z-index: 0; }

	.pirogov__menu {
		z-index: 2;
		margin-top: -.1rem;
		border: .1rem solid $color-1;
		box-shadow: none;
		border-radius: 0; }

	.pirogov__value-container {
		line-height: 1.8rem;
		padding: 0 0 0 1.2rem;

		&.pirogov__value-container--is-multi {
			padding-left: .8rem;
			padding-top: .3rem;
			padding-bottom: .3rem;
			align-items: flex-start; } }

	.pirogov__indicators {
		width: 3rem;
		position: relative;

		&::after {
			@include content();
			width: 1rem;
			height: 1rem;
			border-right: .2rem solid $color-4;
			border-bottom: .2rem solid $color-4;
			right: 1.4rem;
			top: 50%;
			transform: rotate(45deg);
			margin-top: -.75rem; } }

	.pirogov__indicator-separator {
		display: none; }

	.pirogov__indicator {
		display: none; }

	.pirogov__option {
		padding-top: .6rem;
		padding-bottom: .6rem;

		&.pirogov__option--is-focused,
		&.pirogov__option--is-selected {
			background: #ebedf4;
			color: inherit; } }

	&.pirogov--is-disabled {
		opacity: .5;
		filter: grayscale(1); } }
