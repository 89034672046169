@import '../config/base';

.error-page {
  width: 46.8rem;
  min-height: 49.8rem;
  margin: 3.4rem auto 0 auto;
  text-align: center;
  padding: 1rem 4rem;
  background: url('/assets/img/error-bg.jpg') no-repeat 50% 0 / 100% auto;

  h1 {
    font-size: 10.7rem;
    line-height: 12rem;
    color: $colorMain;
    margin-bottom: 1.6rem; }

  p {
    margin-bottom: 3.7rem; } }
