.select {
	position: relative;
	background-color: #fff;

	&::after {
		@include content();
		width: 1rem;
		height: 1rem;
		border-right: .2rem solid $color-4;
		border-bottom: .2rem solid $color-4;
		right: 1.4rem;
		top: 50%;
		transform: rotate(45deg);
		margin-top: -.75rem; }

	select {
		appearance: none;
		width: 100%;
		height: 3.6rem;
		border: .1rem solid $color-4;
		border-radius: 0;
		padding: 0 3rem 0 1.2rem;
		transition: border-color .25s;
		background: none;
		z-index: 1;
		display: block;
		position: relative;

		&::-ms-value {
			background: inherit;
			color: inherit; }

		&:focus {
			border-color: $color-1;
			background: none;

			+ .select-label {
				transform: translate3d(.6rem, -1.75rem, 0) scale(0.875);
				z-index: 1;
				transition: transform .15s linear; } }

		&::-ms-expand {
			display: none; }

		option[disabled] {
			display: none; } }

	&-label {
		position: absolute;
		padding: 0 .6rem;
		left: .7rem;
		top: .8rem;
		max-width: calc(100% - 3.2rem);
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		color: $color-4;
		font-size: 1.6rem;
		height: 2rem;
		line-height: 2rem;
		background-color: #fff;
		transition: transform .15s linear, z-index .25s 0s;
		backface-visibility: hidden;
		will-change: transform;
		cursor: text;
		transform-origin: center left;
		z-index: 0; }

	&.select--required {
		position: relative;

		&::before {
			position: absolute;
			display: block;
			content: '*';
			color: $colorMain;
			font-size: 2.4rem;
			font-weight: 700;
			line-height: 1;
			right: -1.2rem;
			top: -.3rem; } }

	&.select--disabled {
		opacity: .5; }

	&.select--notempty {
		.select-label {
			transform: translate3d(.6rem, -1.75rem, 0) scale(0.875);
			z-index: 1;
			transition: transform .15s linear; } }

	&.select--error {
		select {
			border-color: $colorMain; }

		.select-label {
			color: $colorMain; } } }
