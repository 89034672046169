@import '../config/base';

.login {
  max-width: 35.4rem;
  margin: 0 auto;
  padding: 3.7rem 2.7rem;
  background-color: #fff;
  border: .1rem solid $color-6;
  overflow: hidden;

  &-title {
    line-height: 3.2rem;
    text-transform: uppercase;
    color: $colorMain;
    text-align: center;
    margin-bottom: .7rem; }

  &-footnote {
    font-size: 1.4rem;
    line-height: 1.4;
    text-align: center;
    max-width: 24rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem; }

  .form-item {
    .button {
      width: 100%; } }

  &-separate {
    text-align: center;
    margin: .5rem -2.7rem 1.5rem;

    span {
      display: inline-block;
      position: relative;
      color: $color-4;

      &::before,
      &::after {
        @include content();
        width: 100vw;
        height: .1rem;
        background: $color-3;
        top: 50%; }

      &::before {
        left: 100%;
        margin-left: 1.4rem; }

      &::after {
        right: 100%;
        margin-right: 1.4rem; } } }

  &-message {
    min-height: 8.2rem; }

  &.login--password {
    margin-top: 7.5rem;
    max-width: 53.6rem;
    padding: 3.7rem 3.7rem 4.9rem 3.7rem; }

  &.login--registration {
    padding-left: 4.3rem;
    padding-right: 4.3rem;
    max-width: 53.6rem; }

  &-bottom {
    border-top: .1rem solid $color-3;
    margin-left: -2.7rem;
    margin-right: -2.7rem;
    padding-left: 2.7rem;
    padding-right: 2.7rem;
    text-align: center;
    padding-top: 2rem;
    margin-top: 3rem;
    margin-bottom: -1rem;
    line-height: 1.2;

    a {
      border-bottom: .1rem solid currentColor;

      &:hover {
        border-bottom-color: rgba(#FFF, 0); } } }

  @media (max-width: 599px) {
    max-width: none;
    padding: 0 1rem;
    margin: 0;
    background-color: transparent;
    border: none;

    &-title {
      max-width: 30rem;
      margin: 0 auto 1rem auto;
      font-size: 1.9rem;
      line-height: 2.4rem;
      font-weight: 700;
      letter-spacing: .226rem; }

    &-footnote {
      font-size: 1.7rem;
      line-height: 1.8rem;
      max-width: 30rem; }

    .form {
      max-width: 32rem;
      margin: 0 auto;
      padding-left: 1.5rem;
      padding-right: 1.5rem;

      &-controls {
        margin-left: -2rem;

        .button {
          padding-left: 2rem;
          padding-right: 2rem;
          min-width: 13.5rem;
          margin-left: 2rem;
          flex: none;
 } } }          // white-space: nowrap

    .field {
      &.field--tooltip {
        width: calc(100% - 2.5rem); } }

    .button {
      &--secondary {
        background-color: #fff; } }

    &.login--password {
      margin-top: 0;
      padding: 0; }

    &.login--registration {
      padding-left: 0;
      padding-right: 0;
      max-width: none; }

    &-bottom {
      margin-bottom: .5rem; } } }
