@import '../config/base';

.license-request {
	&-item {
		@include flex();
		border: .1rem solid $color-4;
		background-color: $color-5;
		padding: 1.5rem 0 2rem 1.5rem;
		cursor: pointer;

		&.license-request-item--nohover {
			cursor: default; }

		+ .license-request-item {
			margin-top: 1rem; } }

	&-info {
		flex: 1;
		border-right: .1rem solid $color-4; }

	&-title {
		font-size: 1.7rem;
		line-height: 2.4rem;
		font-weight: 700;
		padding-left: 2.8rem;
		background: url('/assets/img/org-title.svg') no-repeat 0 50% / 1.6rem 1.8678rem; }

	.list {
		@include flex();
		margin: -2rem 0 0 -2rem;

		&-item {
			@include flex-width(50%);
			margin-top: 2rem;
			padding-left: 2rem;

			&-label {
				font-weight: 700; }

			&.list-item--fullwidth {
				@include flex-width(100%); } } }

	&-status {
		@include flex-width(14.5rem);
		@include flex();
		justify-content: flex-start;
		align-content: center;
		padding: 0 2rem; } }
